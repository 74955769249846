import app from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
// import 'firebase/analytics';


let firebaseConfig = {
  apiKey: "AIzaSyACQH9etsTt65ElzID_y_z_dZdNKlNTnGo",
  authDomain: "infomusic-6e76a.firebaseapp.com",
  databaseURL: "https://infomusic-6e76a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "infomusic-6e76a",
  storageBucket: "infomusic-6e76a.appspot.com",
  messagingSenderId: "785896287265",
  appId: "1:785896287265:web:dcbcbda8f780d2eda60760",
  measurementId: "G-7H8EES2R1K"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    //Referenciando a database para acessar em outros locais
    this.app = app.database();
    this.storage = app.storage();
    this.fire = app.firestore();
    this.firebaseFunctions = app.functions();
    // app.analytics();
  }

  login(email, password) {
    return app.auth().signInWithEmailAndPassword(email, password)
  }

  logout() {
    return app.auth().signOut();
  }

  async register(nome, emailusuario, email, password) {
    await app.auth().createUserWithEmailAndPassword(email, password)

    const uid = app.auth().currentUser.uid;

    return app.database().ref('usuarios').child(uid).set({
      nome: nome,
      emailusuario: email
    })
    
  }

  isInitialized() {
    return new Promise(resolve => {
      app.auth().onAuthStateChanged(resolve);
    })
  }

  getCurrent() {
    return app.auth().currentUser && app.auth().currentUser.email
  }

  getCurrentUid() {
    return app.auth().currentUser && app.auth().currentUser.uid
  }

  async getUserName(callback) {
    if (!app.auth().currentUser) {
      return null;
    }

    const uid = app.auth().currentUser.uid;
    await app.database().ref('usuarios').child(uid)
      .once('value').then(callback);

  }

}

export default new Firebase();
