import React from 'react';
import { withRouter } from 'react-router-dom';
import './sobreCSS.css';
// import { Card, Button } from 'react-bootstrap'


function Sobre() {


  return (

    <React.Fragment>

      <div className="containerSobre" >

        <h3 style={{ fontFamily: "'Oswald', sans-serif" }}
        > ACERCA</h3>
        <br />

        <p> <span style={{ fontWeight: "bold" }}> Infomusic</span> nace de nuestras ideas en común sobre las
         necesidades del mundo musical actual y de nuestra propia lucha durante largos años por
         alcanzar visibilidad como intérpretes y docentes en un sector cada vez más disperso.
      </p>

        <p>Somos un equipo formado por dos profesionales de la
        música (cantante lírica y pianista) y un emprendedor social,
        con una larga trayectoria artística y una fructífera
        relación tanto en el ámbito creativo como en el personal.
      </p>
        <p>Las reflexiones surgidas en nuestros encuentros se
        concretaron en materializar algo que pudiera ser realmente útil,
        tanto a toda la comunidad de músicos como a todas las
        personas interesadas en lo que la música les puede aportar.
      </p>
        <p> Con <span style={{ fontWeight: "bold" }}> Infomusic</span> queremos ofrecer un espacio para compartir y relacionarnos más allá del escaparate virtual, abriendo las
         posibilidades a futuras colaboraciones y nuevos proyectos creativos.
      </p>

      </div>

    </React.Fragment>
  );
}

export default withRouter(Sobre)