import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './recuperarSenhaCSS.css';
import { Button, Form } from 'react-bootstrap'
import firebaseApp from 'firebase/app';
import 'firebase/auth';



function UsuarioRecuperarSenha() {

  const [email, setEmail] = useState();
  const [msg, setMsg] = useState();

  async function recuperarSenha() {
    await firebaseApp.auth().sendPasswordResetEmail(email).then(resultado => {
      setMsg('¡Le enviamos un enlace en su correo electrónico para que restablezca su contraseña!');
    }).catch(erro => {
      setMsg('¡Asegúrate de que el correo electrónico sea correcto!');
    })
  }

  return (
    <React.Fragment>
      <div className="containerRecuperar" style={{ minHeight: "630px" }}>
        <Form id="recuperar">
          <h1 style={{ fontFamily: "'Oswald', sans-serif" }}
            className="register-h1">Recuperar contraseña</h1>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email" placeholder="Enter email"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label><span>{msg}</span></Form.Label>
          </Form.Group>

          <Button onClick={recuperarSenha} >Enviar</Button>
        </Form>
      </div>
    </React.Fragment>
  )
}


export default withRouter(UsuarioRecuperarSenha);