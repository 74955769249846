import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import firebase2 from '../Firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import './removerCSS.css';
import { Button, Card, Container, Row, Col, Spinner } from 'react-bootstrap'
import useWindowDimensions from '../useWindowDimensions.js';

function RemoverPremium() {
  const { width } = useWindowDimensions();
  let [premium, setPremium] = useState([]);
  let [checkEmpty, setCheckEmpty] = useState(null);
  let history = useHistory();

  useEffect(() => {    

    // Ler Banco de Dados(Realtime Database) "premium"    
    let posts = firebase2.app.ref('premium');

    posts.on('value', (snapshot) => {
      premium = ([]);
      if (!snapshot.empty) {
        snapshot.forEach((childItem) => {
          if (childItem.key === firebase2.getCurrentUid()) {
            premium.push({
              key: childItem.key,
              titulo: childItem.val().titulo,
            })
          } else (
            setCheckEmpty(false)
          )
        })
      }
      premium.reverse();
      setPremium(premium);
    });
  }, []);
  // Fim useEffect


  async function remover() {
    let posts = firebase2.app.ref('premium');
    const uid = firebase2.getCurrentUid()
    await posts.child(uid).remove().then(resultado => {
      alert('¡Publicación eliminada con éxito!');
    }).catch(erro => {
      alert('Tuvimos un problema al eliminar su publicación. Vuelva a intentarlo más tarde o comuníquese con el soporte técnico.');
    })
    history.push("/dashboard")
  }

  //Obter tempo de leitura  
  let loadingRemoverPremium = premium.map((post) => { return null })

  return (
    <React.Fragment>
      <div id="remover" className="containerRemover">

        <Card className="remover" >

          <Card.Body>

            <Button variant="light" href="/dashboard" type="submit" style={{ color: "red" }}>
              <i className="fas fa-arrow-left"></i>
            </Button>

            <Card.Title style={{ textAlign: "center" }}>
              <i className="fas fa-exclamation-triangle fa-2x"></i>
            </Card.Title>

            <Card.Title style={{
              textAlign: "center", fontFamily: "'Oswald', sans-serif",
              color: "red", fontSize: "20px"
            }}>
              ¡Atención, si elimina alguna publicación, esta acción no se puede deshacer!
                </Card.Title>
            {
              (loadingRemoverPremium.length) ? (
                (firebase.auth().currentUser.emailVerified === false) ? (
                  <div style={{ paddingTop: "50px", textAlign: "center" }}>
                    <Button disabled variant="warning"   >
                      <span style={{ fontFamily: "'Oswald', sans-serif" }}> Atención: para tener acceso a esta funcionalidad necesita autenticar su email! </span>
                    </Button>
                  </div>
                ) : (
                    <>
                      {
                        premium.map((post) => {
                          return (
                            <Card.Body key={post.key} >
                              <br />
                              <Container>
                                <Row>
                                  <Col sm={10} style={{ textAlign: "center", paddingTop: "7px", backgroundColor: "#DCBA8C", borderRadius: "10px" }}>
                                    <span style={{ fontFamily: "'Oswald', sans-serif" }} > <span style={{ color: "red" }}></span> {post.titulo} </span> <br />
                                  </Col>
                                  <Col sm={2}
                                    style={{ textAlign: "center", paddingTop: "1px" }}
                                  >
                                    {(width < 500) ? (<br />) : null}
                                    <Button variant="danger" onClick={() => remover()}>Eliminar</Button>
                                    <br />
                                  </Col>
                                </Row>

                              </Container>
                            </Card.Body>
                          )
                        }
                        )
                      }
                    </>
                  )
              ) : (
                  <div className="loadingRemover" >
                    {
                      (checkEmpty === false) ? (
                        <a href="/dashboard" style={{ color: "blue", fontSize: "35px", textDecoration: "none" }}>
                          No tienes publicaciones!
                        </a>
                      ) : null
                    }

                    <br />
                    {
                      (checkEmpty === null) ? (
                        < span style={{ textAlign: "center" }} >
                          <Spinner animation="grow"
                            variant="primary" style={{ backgroundColor: "#A36920" }} />
                          <br />
                          <p style={{ color: "#A36920" }}>Cargando...</p>
                        </span>
                      ) : null
                    }
                  </div>
                )
            }
          </Card.Body>
        </Card>
      </div>

    </React.Fragment>
  )
}

export default withRouter(RemoverPremium);