import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from '../Firebase';
import './loginCSS.css';
import { Form, Button, Nav } from 'react-bootstrap'

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };

    this.entrar = this.entrar.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    if (firebase.getCurrent()) {
      return this.props.history.replace('/dashboard');
    }
  }

  entrar(e) {
    e.preventDefault();

    this.login();
  }

  login = async () => {
    const { email, password } = this.state;

    try {

      await firebase.login(email, password)
        .catch((error) => {
          if (error.code === 'auth/user-not-found') {
            alert('Este usuario não existe!');
          } else {
            alert('Codigo de erro:' + error.code);
            return null;
          }
        });
      window.location.reload();
      if (firebase.getCurrent()) {
        return this.props.history.replace('/dashboard');
      }


    } catch (error) {
      alert(error.message);
    }

  }


  render() {
    return (
      <React.Fragment>

        <div className="containerLogin">


          <Form onSubmit={this.entrar} id="login" >

            <h1 style={{ fontFamily: "'Oswald', sans-serif" }}
              className="register-h1">Iniciar sesión</h1>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email" placeholder="Enter email"
                autoComplete="off" value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                required
                type="password" placeholder="Enter Password"
                autoComplete="off" value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </Form.Group>


            <Button variant="primary" type="submit">
              Iniciar sesión
            </Button>


            <Nav.Link href="/register">¿Aún no tienes una cuenta?</Nav.Link >
            <Nav.Link href="/recuperarcontrasena"> recuperar contraseña </Nav.Link>


          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);