import React from 'react';
import { withRouter } from 'react-router-dom';
import './infoMusicCSS.css';

function ProfesionalesInfo() {
  return (
    <React.Fragment>

      <div className="containerInfoMusicInfo">

        <h3 style={{ fontFamily: "'Oswald', sans-serif" }}
        > ¿QUÉ ES INFOMUSIC?</h3>
        <br />

        <p> <span style={{ fontWeight: "bold" }}> Infomusic</span> quiere ser un
        punto de encuentro virtual informativo, de todo lo relacionado con la
         práctica musical, tanto para profesionales que quieran exponer
          sus servicios como para usuarios que estén buscando una manera de
           contactar con ellos.
        </p>
        <p>Con la apertura del <span style={{ fontWeight: "bold" }}> mundo musical</span> al universo online, se han multiplicado las posibilidades de conexión y recursos,
          ofreciendo nuevas oportunidades que antes eran impensables,
          pero esta diversificación a veces conlleva una dificultad para
           tener una información más general, directa y especializada.
        </p>
        <p>Por eso <span style={{ fontWeight: "bold" }}> Infomusic</span> ofrece a los usuarios la posibilidad de encontrar todo lo que necesitan en un
        único lugar.
        </p>
        <p><span style={{ fontWeight: "bold" }}> Infomusic</span> está abierto a profesores, músicos profesionales, tiendas de instrumentos, locales
        de ensayo, estudios de grabación.., y en general a cualquier servicio relacionado con la
        práctica o el aprendizaje de la música.
        </p>
        <p>Nuestra voluntad es llegar a ser un sitio de referencia para todo el que busque alguna
        información sobre el tema, y gracias a la participación de todos llegar a ser un espacio
        estratégico en el sector.
        </p>
        <br />
        <h3 style={{ fontFamily: "'Oswald', sans-serif" }}
        > ¿CÓMO FUNCIONA?</h3>
        <br />
        <p>
          Si eres profesor,  profesional de la música, o ofreces servicios musicales….
         mediante una pequeña aportación mensual o anual podrás darte a conocer en <span style={{ fontWeight: "bold" }}> INFOMUSIC</span>,
          mostrando lo que ofreces, enlazando con tu página personal o con tu galería
           audiovisual y consiguiendo una proyección mucho mayor de la que tendrías a
           nivel individual.
        </p>
        <p>
          Nuestros expertos cuidan de que la difusión y el posicionamiento de
          nuestros usuarios y artistas estén en un lugar privilegiado
          según sus necesidades.
        </p>
        <p>
          Nuestro principal objetivo es ayudar a todas las personas que participan
          de Infomusic, puedan ofrecer sus servicios y llegar a su cliente final.
        </p>
        <p>
          Por una cuota simbólica, por menos de lo que cuestan dos cafés al mes,
          puedes formar parte de nuestra comunidad.
        </p>

        <p style={{ textDecoration: "underline", fontWeight: "bold" }}>Opciones de suscripción:</p>

        <p><span style={{ fontWeight: "bold" }}> PROFESORES</span> <br />
        {/* Mensual : 1 euro/mes <br />
        Anual: 10 euros/año (dto 20%) */}
         Anual: 19 euros/año
        </p>

        <p><span style={{ fontWeight: "bold" }}> PROFESIONALES</span> <br />
        {/* Mensual : 2 euro/mes <br />
        Anual: 20 euros/año (dto 20%) */}
        Anual: 29 euros/año
        </p>

        <p><span style={{ fontWeight: "bold" }}> PREMIUM (escuelas, tiendas, espacios)</span> <br />
        {/* Mensual : 3 euro/mes <br />
        Anual: 30 euros/año (dto 20%) */}
        Anual: 39 euros/año
        </p>

        <p>Puedes registrarte ahora mismo,  <a href="/register" style={{ textDecoration: "none" }}> <span style={{ fontWeight: "bold" }}> AQUÍ </span></a>       </p>

        <br />
        <h3 style={{ fontFamily: "'Oswald', sans-serif" }}>¿TIENES DUDAS? CONTACTA CON NOSOTROS <a href="/contactos" style={{ textDecoration: "none" }}> <span style={{ fontWeight: "bold" }}> AQUÍ </span></a>
        </h3>
      </div>

    </React.Fragment>
  );
}

export default withRouter(ProfesionalesInfo)