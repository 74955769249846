import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from '../Firebase';
import firebaseApp from 'firebase/app'
import './escuelasInfoCSS.css';
import { Button, Carousel } from 'react-bootstrap'
import useWindowDimensions from '../useWindowDimensions.js';

const db = firebaseApp.firestore()

function EscuelasInfo() {

  const { width } = useWindowDimensions();
  let [escuelas, setEscuelas] = useState([]);
  let [refreshRender, setRefreshRender] = useState([]);

  useEffect(() => {

    //Ler Banco de dados "escuelas" em Realtime Database
    firebase.app.ref('premium').on('value', (snapshot) => {

      escuelas = ([])

      snapshot.forEach((childItem) => {
        //Ler Banco de dados "customers" em Cloud Firestore
        db.collection('customers')
          .doc(childItem.key)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(async (snapshot) => {

            refreshRender = ([]) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)

            if (!snapshot.empty) {
              snapshot.forEach(async function (doc) {
                if (doc.data().status === "active" && doc.data().role === "Suscripción Premium") {
                  checkSubscription()
                }
              })
            }
            setRefreshRender(refreshRender) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)
          }) //Fim Ler Cloud Firestore

        const checkSubscription = () => {
          escuelas.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
            nome: childItem.val().nome,
            imagem: childItem.val().image,
            tipo: childItem.val().tipo,
          })
        };

      });
      escuelas.reverse();
      setEscuelas(escuelas);
    });

  }, []); //Fim useEffect


  return (

    <React.Fragment>


      <div className="containerEscuelasInfo" style={{ minHeight: "630px" }}>

        <div style={{ textAlign: "center", backgroundColor: "#DCBA8C" }}>
          <Button style={{
            backgroundColor: "#DCBA8C", color: "white",
            fontFamily: "'Oswald', sans-serif",
            border: "none",
            pointerEvents: "none",
            cursor: "default",
            fontSize: "30px",
          }}
            variant="primary" >
            infoMUSIC  ESCUELAS
        </Button>
        </div>
        <br />
        <div style={{ textAlign: "center" }}>
          <p>Busca y encuentra la escuela de Música que necesitas.</p>
          <br />
          <p>Toda la información y opciones para seguir
          adelante con tus estudios de música: Escuelas de Música, Conservatorios, Escuelas Superiores de Música,
           Escuelas de Música especiales, online, oficiales, extranjeras...</p>
        </div>

        <br />
        <br />
        <div style={(width >= 1366 ? { paddingLeft: '400px', paddingRight: '400px' } : null)}>
          <Button href={`/premium/escuelas de musica`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Escuelas de Música
        </Button >
          <Button href={`/premium/conservatorio`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Conservatorio
        </Button>
          <Button href={`/premium/escuela superior de musica`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Escuela Superior de Música
        </Button>
          <Button href={`/premium/escuelas de musica online`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Escuelas de Música Online
        </Button>
          <Button href={`/premium/escuelas especiales de musica`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Escuelas Especiales de Música
        </Button >

        </div>

        <br />

        <div style={{ textAlign: "center" }}>
          <i style={{ color: "#D39B5E" }} className="fas fa-user-tie fa-3x" ></i>
          <br />
          <p>ESCUELAS</p>
          <br />
        </div >


        <Carousel fade={true} >
          {escuelas.map((post) => {
            return (
              (post.tipo === "Escuelas") ? (
                <Carousel.Item interval={1000} >
                  <img className={(width > 800 ? 'imagemEscuelasInfo' : 'imagemEscuelasInfoMobile')} src={post.imagem} alt="Publication Cover" />
                  <Carousel.Caption>
                    <Button href={`/premium/publication/${post.chavepost}`} style={{ backgroundColor: '#A36920', borderColor: "#A36920" }}>{post.nome}</Button>
                  </Carousel.Caption>
                </Carousel.Item>
              ) : null
            )
          })}
        </Carousel>

      </div>

      {/* icon retorno */}
      <div style={{ textAlign: "center", paddingBottom: "20px", paddingTop: "20px" }}>
        <a href="/escuelas#">
          <i style={{ color: "#693B00" }} className="fas fa-chevron-up fa-3x "></i>
        </a>
      </div>

    </React.Fragment>
  );
}

export default withRouter(EscuelasInfo)




