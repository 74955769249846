import React from 'react';
import { withRouter } from 'react-router-dom';
import './politicaPrivacidadCSS.css';

function PoliticaPrivacidad() {
  return (
    <React.Fragment>

      <div className="containerPrivacidad">

        <h1 style={{ fontFamily: "'Oswald', sans-serif" }}
          className="register-h1"> Política de privacidad InfoMusicOnline</h1>
        <p>
          www.infomusiconline.com es un dominio de Internet registrado por infomusiconline
          con domicilio administrativo en Carre Vazquez de Mella 3, – 08224 Terrassa (BARCELONA).
          Infomusiconline implica la aceptación expresa de todas y cada una de las siguientes:
        </p>
        <p>Condiciones Generales de uso de la WEB: http:/ www.infomusiconline.com:</p>

        <h4> 1.	CAPACIDAD LEGAL:</h4>
        <p>El usuario declara que es mayor de edad (mayor de 18 años) y dispone de
        capacidad legal necesaria para contratar los Productos y Servicios ofertados en
        la WEB de INFOMUSICONLINE manifestando asimismo que acepta la
        vinculación a este acuerdo y entiende, y acepta en su totalidad las condiciones
        aquí enunciadas para utilizar esta WEB y/o contratar los Productos y Servicios
        ofertados.
      </p>
        <h4> 2. FINALIDADES:</h4>
        <p>
          INFOMUSICONLINE le informa de que los datos de carácter personal cuyo
          tratamiento se realiza en concepto de responsable tienen como finalidad
          gestionar las relaciones contractuales de las que es parte, así como ofrecer a
          las personas y entidades interesadas cualquier información sobre actividades,
          productos y servicios relacionados con INFOMUSICONLINE especialmente los
          referidos a:
      </p>
        <p>
          Servicio de Marketplace (plataforma) de ofertas, demandas y servicios para
          músicos y elementos relacionados con la música.
          Las comunicaciones indicadas podrán ser realizadas por cualquier medio,
          incluidos los medios electrónicos. El interesado deberá autorizar el envío de
          comunicaciones de forma explícita, mediante el marcado de las
          correspondientes casillas, en cualquiera de los formularios de solicitud de datos
          que se proporcionen.
      </p>
        <h4> 3. EL USUARIO:</h4>
        <p>
          Que haya contratado o que se informe sobre los diferentes Servicios que
          ofrece INFOMUSICONLINE declara que toda la información suministrada a la
          hora de registrarse es verdadera, completa y precisa:

      </p>
        <p>
          El usuario infomusiconline.com garantiza y responde siempre de la veracidad,
          exactitud, vigencia y autenticidad de los Datos de Carácter Personal, en
          adelante los DATOS, facilitados, y se compromete a mantenerlos debidamente
          actualizados.
      </p>
        <p>
          El usuario autoriza expresamente la comunicación de los DATOS a terceros
          cuando resulte necesaria para la adecuada prestación de los Servicios
          ofrecidos por INFOMUSICONLINE y requeridos por los usuarios.
      </p>
        <p>
          El usuario da su consentimiento expreso para recibir comunicaciones
          electrónicas con publicidad y novedades comerciales de INFOMUSICONLINE y
          de los diferentes Productos y Servicios comercializados o promocionados por
          INFOMUSICONLINE.
      </p>

        <h4>4. DERECHOS DEL INTERESADO:</h4>
        <p>Cualquier persona tiene derecho a obtener confirmación sobre si en
        INFOMUSICONLINE estamos tratando datos personales que le conciernan o no.
        Los interesados tienen derecho a acceder a sus datos personales así como a
        solicitar la rectificación de datos inexactos o, en su caso, solicitar su supresión
        cuando, entre otros motivos, los datos ya no sean necesarios para los fines
para los que fueron recogidos.</p>
        <p>
          En determinadas circunstancias y por motivos relacionados con su situación
          particular, los interesados podrán oponerse al tratamiento de sus datos. En
          este caso, INFOMUSICONLINE dejará de tratar los datos, salvo por motivos
          legítimos, imperiosos, o el ejercicio o defensa de posibles reclamaciones, y los
          mantendremos debidamente bloqueados durante el plazo correspondiente
          mientras persistan las obligaciones legales.
</p>

        <p>
          En determinadas circunstancias, los clientes o interesados podrán solicitar la
          limitación del tratamiento, de modo que INFOMUSICONLINE sólo los reservará
          y utilizará en los casos autorizados por la Ley.
</p>
        <p>
          Los interesados tienen derecho a la portabilidad de sus datos de modo que
          pueden solicitar y recibir los datos que le incumban y que nos haya facilitado o
          solicitar que se los enviemos a otro responsable del tratamiento de su elección,
          en un formato estructurado de uso común y lectura mecánica.
</p>
        <p>
          El interesado podrá ejercer cualquiera de estos derechos mediante el envío de
          un correo electrónico a la dirección info@infomusiconline.com, adjuntando
          copia del DNI o documento identificativo sustitutorio e indicando claramente el
          derecho al que se refiera. Asimismo podrá realizar la solicitud por correo postal
          a la dirección indicada anteriormente.
</p>
        <p>
          La respuesta a las peticiones que se realicen se llevará a cabo con la mayor
          brevedad posible y, en todo caso, en el plazo de un mes desde la recepción de
          su solicitud. Dicho plazo podrá prorrogarse otros dos meses en caso necesario,
          teniendo en cuenta la complejidad y el número de solicitudes. El responsable
          informará al interesado de la prórroga dentro del primer mes desde la
          solicitud.
</p>

        <h4>5. INFOMUSICONLINE:</h4>
        <p>
          De conformidad con la vigente Ley Orgánica 3/2018 de Protección de Datos de
          Carácter Personal y garantía de derechos digitales, y reglamento posterior,
          Real Decreto 1720/2007 y nuevo reglamento Europeo 2016/679 de 25 Mayo
          2018, los DATOS enviados por el usuario a través de
          www.infomusiconline.com, serán tratados por INFOMUSICONLINE de forma
          automatizada y serán incorporados al Registro de Actividades de Tratamiento
          con la finalidad de poderle ofrecer y gestionar los Productos y Servicios de
          INFOMUSICONLINE.
</p>
        <p>
          Dicho Registro de Actividades de Tratamiento cuyo Responsable es la sociedad
          mercantil INFOMUSICONLINE con domicilio administrativo Carrer Vazquez de
          Mella 3, 1-3
          08224 Terrassa (BARCELONA).e-mail: info@infomusiconline.com Los titulares
          de los DATOS tienen en todo momento el derecho de acceder al fichero,
          pudiendo ejercitar los derechos de rectificación, cancelación, oposición y
          eliminación en los términos recogidos en la legislación de protección de datos.
          Del mismo modo, el consentimiento del usuario para el tratamiento y cesión de
          sus datos personales será revocable en todo momento sin efectos retroactivos,
          conforme a lo que disponen los artículos 6 y 11 de la Ley Orgánica 3/2018, de
          5 de diciembre de Protección de Datos de Carácter Personal y garantía de
          derechos digitales y reglamento posterior, Real Decreto 1720/2007 y nuevo
          reglamento Europeo 2016/679 de 25 Mayo 2018.
          Para los fines contenidos en la presente cláusula bastará con contactar con
          INFOMUSICONLINE a través del correo electrónico info@infomusiconline.com,
          o bien, escribiendo a su domicilio administrativo sito en Carrer Vazquez de
          Mella 3, 1-3
          08224 Terrassa (BARCELONA). INFOMUSICONLINE garantiza que ha adoptado
          las medidas de seguridad necesarias en sus instalaciones, sistemas, servidores
          y ficheros para evitar el acceso a los DATOS de personas no autorizadas.
          Sin perjuicio de lo anterior dichos DATOS podrán ser revelados a las
          autoridades públicas solo en el supuesto de que las mismas así lo requiriesen
          de conformidad con las disposiciones legales y reglamentos aplicables al caso.
</p>

        <h4>6. USO DE LA PÁGINA</h4>
        <p>www.infomusiconline.com INFOMUSICONLINE:
        La utilización de la página www,infomusiconline.com y/o de los Productos y
        Servicios de INFOMUSICONLINE, supondrá la aceptación plena y la validez, de
        todas y cada de las Condiciones Generales contenidas en la última versión de
        las Condiciones Generales por lo que el usuario deberá ser consciente de la
        importancia de leerlas cada vez que visite www.infomusiconline.com
        INFOMUSICONLINE podrá modificar unilateralmente, en cualquier momento y
        sin previo aviso, estas Condiciones Generales así como los Producto y Servicios
        prestados o cualquier otro aspecto sustancial o no de la presente página, o las
        condiciones de operatividad, técnicas y de uso de los servicios de
        naturplanet.com. Del mismo modo, los usuarios, con objeto de mejorar el
        servicio y establecer un óptimo nivel de calidad, objetivo último de
        INFOMUSICONLINE podrán sugerir aquellas modificaciones que ellos estimen
        útiles, poniéndose en contacto con los responsables de la página a través de la
        dirección de correo electrónico info@infomusiconline.com. El usuario se
        compromete a utilizar la página www.infomusiconline.com y aquellos Productos
        y Servicios puestos a su disposición a través de la misma, de manera acorde a
        la ley, las buenas costumbres y el orden público, así como con lo dispuesto en
        las presentes Condiciones Generales. En consecuencia, queda obligado a no
        utilizar la página www.infomusiconline.com o los Productos y Servicios
        ofrecidos, con fines o efectos ilícitos y/o contrarios a lo establecido en las
        presentes Condiciones Generales, o lesivos de derechos y/o intereses de
        terceros o que, de cualquier forma, puedan dañar la página
        www.infomusiconline.com o impedir su normal uso, o de los Productos y
        Servicios accesibles a través de las misma, o por el resto de los usuarios, o por
        INFOMUSICONLINE y/o que afecten o pudiesen afectar la imagen de
        INFOMUSICONLINE.
        </p>
        <p>
          Los usuarios de naturplanet.com deberán observar cualquier instrucción que, a
          través de e-mail info@infomusiconline.com o, INFOMUSICONLINE o su
          personal, debidamente autorizado, imparta.
          El usuario acepta todas y cada una de las obligaciones o prohibiciones que en
          cada momento imponga la legislación en vigor en relación a la exclusión o
          limitación de responsabilidad del profesional en el cumplimiento de los
          servicios.
</p>

        <h4>7. LOS SERVICIOS DE INFOMUSICONLINE:</h4>
        <p>
          No están destinados a menores y no solicita ni recoge información relativa a
          personas menores de edad.
</p>

        <h4>8. MOTIVOS DE EXCLUSIÓN:</h4>
        <p>
          INFOMUSICONLINE se reserva el derecho de excluir, temporal o
          definitivamente al usuario en cualquiera de los siguientes supuestos:
          </p>
        <p>
          Por incumplimiento de cualquiera de las Condiciones Generales de Uso
          establecidos en el presente documento.
</p>
        <p>
          Por incumplimiento de las leyes, las buenas costumbres, y el Orden público.
          Por incumplimiento de cualquier otra obligación que tuviese el usuario con La
          exclusión del usuario no supondrá la renuncia de INFOMUSICONLINE a realizar
          las acciones legales correspondientes o las indemnizaciones que en derecho
          correspondan.
</p>

        <h4> 9. PROPIEDAD INTELECTUAL Y DERECHOS DE AUTOR:</h4>
        <p>
          Toda la información contenida en naturplanet.com, su diseño gráfico y el
          código en lenguaje HTML, JAVA, JAVA Script o Active X, está protegida por
          derechos de autor u otros derechos de protección de la propiedad intelectual.
          Estos derechos pertenecen exclusivamente a INFOMUSICONLINE o a sus
          licenciadores. Los usuarios de Internet que accedan a este WEB pueden
          visualizar la información contenida en el mismo y efectuar downloads o
          reproducciones privadas en su sistema informático, siempre que los elementos
          reproducidos no sean cedidos posteriormente a terceros o se instalen en un
          servidor conectado a Internet o a una red local. No se permite, sin perjuicio de
          lo recogido en las presentes Condiciones Generales, la distribución,
          modificación, cesión, comunicación pública, reproducciones ni cualquier otro
          acto de parte o la totalidad de la información publicada en naturplanet.com, sin
          autorización previa de INFOMUSICONLINE . El usuario, deberá utilizar los
          contenidos e informaciones recogidos en www.infomusiconline.com de forma
          diligente, correcta y lícita, y en concreto, únicamente para uso personal y no
          comercial, siempre y cuando no se elimine o modifique el contenido o cualquier
          mención de fuentes, copyright y demás datos identificativos de derechos de
          INFOMUSICONLINE o de terceros, es decir respetando su forma original.
          Cualquier reproducción o copia, distribución o publicación, de cualquier clase,
          del contenido de la información publicada en www.infomusiconline.com sin
          autorización previa y por escrito de INFOMUSICONLINE reproducción puede
          solicitarse a la dirección de correo electrónico rubencasadocodi@hotmail.com
          En caso de que cualquier usuario o tercero considere que cualquiera de los
          contenidos existentes en naturplanet.com ha sido introducido en la misma con
          violación de derechos de autor u otros derecho INFOMUSICONLINE dicha
          circunstancia, remitiendo notificación a la dirección de correo electrónico
          info@infomusiconline.com, e incluyendo al menos, los siguientes extremos: a)
          Nombre, dirección, número de teléfono y dirección de e-mail del reclamante.
          b) Datos del titular de los derechos de autor u otros derechos de protección de
          la propiedad intelectual que pudiesen haber sido infringidos. c) Indicación de
          los contenidos infringidos y ubicación en la página www.infomusiconline.com d)
          Declaración de que la introducción de los contenidos ha sido introducida sin
          autorización expresa del titular de los derechos de autor u otros derechos de
          propiedad intelectual.
</p>
        <p>
          Sin perjuicio de lo señalado en los párrafos precedentes, INFOMUSICONLINE,
          se reserva el derecho de defenderse contra reclamaciones fundadas en la
          normativa vigente sobre publicidad y de derechos de autor u otros derechos
          protejan la propiedad intelectual.
</p>

        <h4>10. HIPERLINKS:</h4>
        <p>
          En el caso de que www,infomusiconline.com refiera mediante enlaces a
          publicidad o a páginas WEB de terceros, INFOMUSICONLINE no se obliga a
          controlar y no controla con carácter previo, ni aprueba ni hace propios los
          servicios, contenidos, datos, archivos, productos y cualquier clase de material
          o información existente en la página o páginas WEB de terceros, por lo que
          INFOMUSICONLINE no responderá, bajo ninguna circunstancia, de la legalidad
          de los contenidos de dicha/ s página/ s, siendo responsabilidad exclusiva del
          tercero, a título enunciativo y no limitativo, el respeto de la legalidad, las
          buenas costumbres, el orden público, los contenidos, así como que aquellos no
          lesionen los derechos de terceros. La existencia de un hiperlink no presupone
          relación de ninguna clase entre INFOMUSICONLINE y el propietario de la
          página WEB en que el mismo se establezca.
</p>
        <p>
          Aquellos usuarios o terceras personas que pretendan establecer un hiperlink
          con la página www.infomusiconline.com, deberán garantizar que el hiperlink
          sólo permite el acceso a las páginas o servicios de la página
          www.infomusiconline.com, pero no realiza, de forma enunciativa pero no
          limitativa, reproducción de contenidos, deep-links, browsers, manifestaciones
          inexactas o incorrectas, sobre los contenidos o la página WEB de
          www.infomusiconline.com Salvo aquellos signos que formen parte del
          hiperlink, el usuario garantizará que la página WEB en la que se establezca el
          hiperlink no contendrá marcas, nombres comerciales, rótulos de
          establecimientos, denominaciones, logotipos, eslóganes o cualquier tipo de
          signo distintivo perteneciente a INFOMUSICONLINE.
</p>


        <h4>11. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD:</h4>
        <p>
          INFOMUSICONLINE no garantiza la fiabilidad, disponibilidad, o continuidad del
          funcionamiento de su página WEB ni de los Productos y Servicios puestos a
          disposición del usuario, por lo que excluye cualquier responsabilidad por los
          daños y/o perjuicios de toda naturaleza que puedan deberse a la falta de
          disponibilidad, fiabilidad, o continuidad de su página WEB o de sus servicios,
          aunque intentará facilitar, en la medida de sus posibilidades, ayuda técnica a la
          persona afectada.
</p>
        <p>
          INFOMUSICONLINE no se obliga a controlar y no controla con carácter previo,
          la ausencia de virus o elementos en los contenidos, que puedan producir
          alteraciones en el software o hardware de los usuarios o personas que visiten
          las páginas WEB, por lo que no responderá de los daños y perjuicios de
          cualquier naturaleza que pudieran derivarse de los mismos.
</p>

        <h4> 12. SALVAGUARDA DE LAS CONDICIONES GENERALES:</h4>
        <p>
          Si una de las estipulaciones de las presentes Condiciones Generales fuera
          declarada nula o inoperante, el resto de las Condiciones Generales se
          mantendrán vigentes.
</p>

        <h4>13. ERRORES TIPOGRÁFICOS:</h4>
        <p>
          INFOMUSICONLINE hace todos los esfuerzos necesarios dentro de sus medios
          para ofrecer la información contenida en el WEB site de forma veraz y sin
          errores tipográficos. En el caso que en algún momento se produjera algún
          error de este tipo, ajeno en todo momento a la voluntad de
          NINFOMUSICONLINE se procedería inmediatamente a su corrección. De existir
          un error tipográfico en alguno de los precios mostrados y algún Cliente hubiera
          tomado la decisión de adquirir los Productos y Servicios basándose en dicho
          error INFOMUSICONLINE se lo comunicará al Cliente y éste tendrá derecho a
          rescindir sus servicios sin ningún coste por su parte.
</p>

        <h4> 14. ACEPTACIÓN DE LAS CONDICIONES GENERALES:</h4>
        <p>
          La inscripción del usuario como cliente de INFOMUSICONLINE implica el
          conocimiento y aceptación expresa de las presentes Condiciones Generales. La
          contratación de un Producto o Servicio concreto ofertado por los proveedores
          de servicios que figuran en INFOMUSICONLINE implica la aceptación explícita
          de las Condiciones Generales.
</p>
        <h4>15. LEY APLICABLE Y JURISDICCIÓN COMPETENTE:</h4>
        <p>
          Estas Condiciones Generales se rigen por la Ley Española y Nuevo Reglamento
          Europeo. Cualquier controversia que pudiera surgir, con respecto a su validez,
          ejecución, cumplimiento o resolución, total o parcial, será sometida por las
          partes, con renuncia expresa a sus propios fuero (o a cualquier otro que, en su
          caso, pudiera corresponderles), a la competencia de los Juzgados y Tribunales
          de Barcelona.
</p>
        <p>
          Para no recibir información sobre sus productos y servicios ni de otras
          entidades, marque la casilla correspondiente en el formulario.
          En aquellos casos donde para realizar una solicitud sea necesario cumplimentar
          un formulario y hacer un “clic” en el botón de enviar, la realización del mismo
          implicará necesariamente que ha sido informado y ha otorgado expresamen
</p>


        <h4> DATOS FACILITADOS POR TERCEROS: </h4>
        <p>En caso de que en una solicitud se incluyeran DATOS por personas no titulares
        de los mismos el usuario deberá, con carácter previo a su inclusión, informar a
        dichas personas de los extremos contenidos en los párrafos anteriores.
</p>

      </div>
    </React.Fragment>
  );
}

export default withRouter(PoliticaPrivacidad)