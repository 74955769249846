import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import firebase from '../Firebase';
import firebaseApp from 'firebase/app';
import './premiumAnuncioCSS.css';
import {
  Card, Button, Spinner, ListGroupItem, Accordion,
  OverlayTrigger, CardGroup, Container, Row, Col,
  Tooltip
} from 'react-bootstrap'
import useWindowDimensions from '../useWindowDimensions.js';
import logo from '../img/infomusicLogo.png'

const db = firebaseApp.firestore()

let categoriaAnunciante = null
let nomeAnunciante = null
let tipoAnunciante = null

function PremiumAnuncio() {

  const { width } = useWindowDimensions();
  let [premium, setPremium] = useState([]);
  let { idPremiumAnuncio } = useParams();
  let [refreshRender, setRefreshRender] = useState([]);

  useEffect(() => {

    //Ler Banco de dados "premium" em Realtime Database
    firebase.app.ref('premium').on('value', (snapshot) => {

      premium = ([])

      snapshot.forEach((childItem) => {
        //Ler Banco de dados "customers" em Cloud Firestore
        db.collection('customers')
          .doc(childItem.key)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(async (snapshot) => {

            refreshRender = ([]) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)

            if (!snapshot.empty) {
              snapshot.forEach(async function (doc) {
                if (doc.data().status === "active" && doc.data().role === "Suscripción Premium") {
                  checkSubscription()
                }
              })
            }
            setRefreshRender(refreshRender) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)
          }) //Fim Ler Cloud Firestore

        const checkSubscription = () => {
          premium.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
            nome: childItem.val().nome,
            tipo: childItem.val().tipo,
            titulo: childItem.val().titulo,
            resumo: childItem.val().resumo,
            descricao: childItem.val().descricao,
            imagem: childItem.val().image,
            site: childItem.val().site,
            whatsapp: childItem.val().whatsapp,
            facebook: childItem.val().facebook,
            instagram: childItem.val().instagram,
            telefone: childItem.val().telefone,
            email: childItem.val().email,
            idioma: childItem.val().idioma,
            valor: childItem.val().valor,
            pais: childItem.val().pais,
            cidade: childItem.val().cidade,
            horario: childItem.val().horario,
            nivelestudo: childItem.val().nivelestudo,
            experiencia: childItem.val().experiencia,
            presencialonline: childItem.val().presencialonline,
            youtube: childItem.val().youtube,
            pagamento: childItem.val().pagamento,
            categoria: childItem.val().categoria,
          })
        };

      });
      premium.reverse();
      setPremium(premium);
    });

  }, []); //Fim useEffect

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      ¡No informado!
    </Tooltip>
  );

  //Obter tempo de leitura  
  let loadingPremium = premium.map(() => { return null })

  return (
    <>
      {/* Obter categoria e nome do anunciante */}
      {premium.map((post) => {
        if (idPremiumAnuncio === post.chavepost) {
          categoriaAnunciante = post.categoria
          nomeAnunciante = post.nome
          tipoAnunciante = post.tipo.toUpperCase()
        }
        return null
      })}

      <div className="containerPremium">

        {/* Barra de informações */}
        <Container style={{
          backgroundColor: "#DCBA8C",
          textAlign: "center",
        }} >
          <Row>
            <Col>
              <Button style={{
                backgroundColor: "#A36920", color: "white",
                fontFamily: "'Oswald', sans-serif",
                border: "none",
                fontSize: "20px",
                wordBreak: 'break-all',
                // textTransform: 'capitalize'
              }}>
                {tipoAnunciante} {width <= 490 ? <br /> : null} {categoriaAnunciante} {width <= 490 ? <br /> : null} <i className="fas fa-arrow-circle-right"></i> {width <= 490 ? <><br /><br /></> : null} <span style={{ backgroundColor: "#693B00", padding: '5px' }}> {nomeAnunciante} </span>
              </Button>
            </Col>
          </Row>
        </Container>
        <br />


        {(loadingPremium.length) ? (
          <section>
            {
              <CardGroup className="cardGroupPremium" >

                {/* Percorre array */}
                {
                  premium.map((post) => {
                    return (

                      <div key={post.chavepost}>
                        {

                          ((post.tipo === "Escuelas" || post.tipo === "Tiendas" || post.tipo === "Espacios")
                            && post.chavepost === idPremiumAnuncio) ? (

                            <article>

                              {/* ------Inicio CARD----------------- */}
                              <div className="card mb-3" style={{ maxWidth: "1040px", backgroundColor: "#DCBA8C" }}>
                                <div className="row g-0">
                                  <div className="col-md-4">
                                    {/* ------Imagem----------------- */}
                                    {(post.imagem === '') ? (
                                      <Card.Img
                                        className={(width >= 768 ? 'cardImagemPremiumAnuncio' : 'cardImagemPremiumAnuncioMobile')}
                                        src={logo} alt="Capa do post"
                                      />
                                    ) : (
                                      <Card.Img
                                        className={(width >= 768 ? 'cardImagemPremiumAnuncio' : 'cardImagemPremiumAnuncioMobile')}
                                        src={post.imagem} alt="Capa do post"
                                      />
                                    )}

                                  </div>
                                  <div className="col-md-8">
                                    <div style={{ textAlign: "justify" }} className="card-body">

                                      <h5 className="card-title" style={{ fontFamily: "'Crimson Pro', serif", color: "black", fontWeight: "bold", textAlign: "center" }}>{post.titulo}</h5>

                                      <p className="card-text"><span style={{
                                        color: "black",
                                        wordBreak: 'break-all',
                                        whiteSpace: "pre-line"
                                      }}> {post.descricao} </span> </p>

                                      {/* <p className="card-text"><span style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          wordBreak: 'break-all',
                                          whiteSpace:"pre-line"
                                        }}> Estudios/Formación:</span> {post.nivelestudo}</p> */}

                                      {/* <p className="card-text"><span style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          wordBreak: 'break-all',
                                          whiteSpace:"pre-line"
                                        }}> Experiencia:</span> {post.experiencia}</p> */}

                                      <p className="card-text"><span style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        wordBreak: 'break-all',
                                        whiteSpace: "pre-line"
                                      }}> Idiomas:</span> {post.idioma}</p>

                                      {(post.horario !== '') ? (
                                        <p className="card-text"><span style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          wordBreak: 'break-all',
                                          whiteSpace: "pre-line"
                                        }}> Horarios:</span> {post.horario}</p>
                                      ) : null}

                                      {(post.valor !== '') ? (
                                        <p className="card-text"><span style={{
                                          color: "black",
                                          fontWeight: "bold",
                                          wordBreak: 'break-all',
                                          whiteSpace: "pre-line"
                                        }}> Precio:</span> {post.valor}</p>
                                      ) : null}

                                      <p className="card-text"><span style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        wordBreak: 'break-all',
                                        whiteSpace: "pre-line"
                                      }}> País:</span> {post.pais}</p>

                                      <p className="card-text"><span style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        wordBreak: 'break-all',
                                        whiteSpace: "pre-line"
                                      }}> Ciudad:</span> {post.cidade}</p>

                                      <p className="card-text"><span style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        wordBreak: 'break-all',
                                        whiteSpace: "pre-line"
                                      }}> Presencial / Online:</span> {post.presencialonline}</p>

                                      <p style={{
                                        textAlign: "left"
                                      }} ><span style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        wordBreak: 'break-all',
                                        whiteSpace: "pre-line"
                                      }}> Email:</span> {post.email}</p>

                                      <p style={{
                                        textAlign: "left"
                                      }} ><a href={post.site} rel="noreferrer" target="_blank"
                                        style={{
                                          color: "black",
                                          fontWeight: "bold"
                                        }}> {post.site}</a> </p>

                                      {/* ------(nivel 01 icones) Telefone, WebSites e Redes Sociais ----------------- */}
                                      <ListGroupItem style={{ padding: "10px" }} >
                                        <Card >
                                          <div style={{ textAlign: 'center' }}>
                                            {/* ------TelefoneIcon----------------- */}
                                            {(post.telefone === "") ? (
                                              <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                              >
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                  <i style={{ color: "#2F4F4F" }} className="fas fa-mobile-alt fa-2x"> </i>
                                                </Accordion.Toggle>
                                              </OverlayTrigger>
                                            ) : (

                                              <Button variant="light"
                                                href={`tel:+${post.telefone}`}
                                                rel="noreferrer" >
                                                <i style={{ color: "#2F4F4F" }} className="fas fa-mobile-alt fa-2x"> </i>
                                              </Button>

                                            )}


                                            {/* ------FacebookIcon----------------- */}
                                            {(post.facebook === "") ? (

                                              <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                              >
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                  <i style={{ color: '#1e90ff' }} className="fab fa-facebook-f fa-2x" > </i>
                                                </Accordion.Toggle>
                                              </OverlayTrigger>


                                            ) : (
                                              <Button variant="light"
                                                href={`https://www.facebook.com/${post.facebook}`}
                                                rel="noreferrer" target="_blank">
                                                <i style={{ color: '#1e90ff' }} className="fab fa-facebook-f fa-2x" > </i>
                                              </Button>

                                            )}

                                            {/* ------InstagramIcon----------------- */}
                                            {(post.instagram === "") ? (

                                              <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                              >
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                  <i style={{ color: "#e1306c" }} className="fab fa-instagram fa-2x" > </i>
                                                </Accordion.Toggle>
                                              </OverlayTrigger>


                                            ) : (
                                              <Button variant="light"
                                                href={`https://www.instagram.com/${post.instagram}`} rel="noreferrer" target="_blank">
                                                <i style={{ color: "#e1306c" }} className="fab fa-instagram fa-2x" > </i>
                                              </Button>

                                            )}

                                            {/* ------WhatsappIcon----------------- */}

                                            {(post.whatsapp === "") ? (

                                              <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                              >
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                  <i style={{ color: "#25D366" }} className="fab fa-whatsapp fa-2x" > </i>
                                                </Accordion.Toggle>
                                              </OverlayTrigger>


                                            ) : (

                                              <Button variant="light"
                                                href={`https://wa.me/${post.whatsapp}?text=Hola, encontré tu contacto en el sitio web de info MUSIC online! http://prueba.infomusic.online`} rel="noreferrer" target="_blank">
                                                <i style={{ color: "#25D366" }} className="fab fa-whatsapp fa-2x" > </i>
                                              </Button>


                                            )}

                                            {/* ------WebsiteIcon----------------- */}

                                            {(post.site === "") ? (

                                              <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                              >
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                  <i style={{ color: "#87CEFA" }} className="fas fa-globe fa-2x"> </i>                                </Accordion.Toggle>
                                              </OverlayTrigger>

                                            ) : (

                                              <Button variant="light"
                                                href={post.site} rel="noreferrer" target="_blank">
                                                <i style={{ color: "#87CEFA" }} className="fas fa-globe fa-2x"> </i>
                                              </Button>


                                            )}

                                            {/* ------YoutubeiteIcon----------------- */}

                                            {(post.youtube === "") ? (

                                              <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                              >
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                  <i style={{ color: "red" }} className="fab fa-youtube fa-2x"> </i>                                </Accordion.Toggle>
                                              </OverlayTrigger>

                                            ) : (

                                              <Button variant="light"
                                                href={post.youtube} rel="noreferrer" target="_blank">
                                                <i style={{ color: "red" }} className="fab fa-youtube fa-2x"> </i>
                                              </Button>


                                            )}

                                            {/* ------E-mailIcon----------------- */}
                                            {(post.email === "") ? (

                                              <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                              >
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                  <i style={{ color: "#87CEFA" }} className="fas fa-envelope fa-2x"> </i>
                                                </Accordion.Toggle>
                                              </OverlayTrigger>


                                            ) : (
                                              <Button variant="light"
                                                href={`mailto:${post.email}`} rel="noreferrer">
                                                <i style={{ color: "#87CEFA" }} className="fas fa-envelope fa-2x"> </i>
                                              </Button>
                                            )}
                                          </div>
                                        </Card>

                                      </ListGroupItem>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </article>
                          ) : null}
                      </div>
                    );

                  })
                }
              </CardGroup>
            }
          </section >
        ) : (

          <div className="loadingPremiumAnuncio" >

            <span style={{ textAlign: "center" }} >
              <Spinner animation="grow"
                variant="primary" style={{ backgroundColor: "#A36920" }} />
              <br />
              <p style={{ color: "#A36920" }}>Cargando...</p>
            </span>

          </div>
        )
        }
        {/* icon retorno  */}
        <div style={{ textAlign: "center", paddingBottom: "20px", paddingTop: "20px" }}>
          <a href={`/premium/publication/${idPremiumAnuncio}#`}>
            <i style={{ color: "#693B00" }} className="fas fa-chevron-up fa-3x "></i>
          </a>
        </div>
      </div >

    </>
  )
}

export default withRouter(PremiumAnuncio)