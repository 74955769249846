import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button, ProgressBar, Spinner } from 'react-bootstrap'
import firebase2 from '../Firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import espanaImage from "../img/spain.png"
import './editarValorCSS.css';


class EditarPremium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editarValor: [],
      tipo: '',
      nome: '',
      categoria: '',
      titulo: '',
      imagem: null,
      url: '',
      resumo: '',
      descricao: '',
      site: '',
      whatsapp: '',
      facebook: '',
      instagram: '',
      youtube: '',
      telefone: '',
      email: '',
      idioma: '',
      pais: 'España',
      cidade: '',
      local: '',
      horario: '',
      nivelestudo: '',
      experiencia: '',
      presencialonline: '',
      progress: 0,
    };
    this.atualizar = this.atualizar.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }


  //////////////////////////Obter dados do banco: "posts"///////////////////

  componentDidMount() {

    if (!firebase2.getCurrent()) {
      this.props.history.replace('/');
      return null;
    }

    let posts = firebase2.app.ref('premium');
    
    posts.once('value', (snapshot) => {

      let state = this.state;
      state.editarValor = [];

      snapshot.forEach((childItem) => {
        if (childItem.key === firebase2.getCurrentUid()) {
          state.editarValor.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
            tipo: childItem.val().tipo,
            nome: childItem.val().nome,
            categoria: childItem.val().categoria,
            titulo: childItem.val().titulo,
            resumo: childItem.val().resumo,
            descricao: childItem.val().descricao,
            imagem: childItem.val().image,
            site: childItem.val().site,
            whatsapp: childItem.val().whatsapp,
            facebook: childItem.val().facebook,
            instagram: childItem.val().instagram,
            telefone: childItem.val().telefone,
            email: childItem.val().email,
            idioma: childItem.val().idioma,
            valor: childItem.val().valor,
            pais: childItem.val().pais,
            cidade: childItem.val().cidade,
            horario: childItem.val().horario,
            nivelestudo: childItem.val().nivelestudo,
            experiencia: childItem.val().experiencia,
            presencialonline: childItem.val().presencialonline,
            youtube: childItem.val().youtube,
          })
        }
      });
      state.editarValor.reverse();
      this.setState(state);
      this.alterarStates()
    })
  }

  alterarStates() {
    this.state.editarValor.map((post) => {
      return (
        <>
          {
            (this.props.match.params.idEditarPremium === post.chavepost) ? (
              this.setState({ nome: post.nome }),
              this.setState({ titulo: post.titulo }),
              this.setState({ imagem: post.imagem }),
              this.setState({ url: post.imagem }),
              this.setState({ tipo: post.tipo }),
              this.setState({ categoria: post.categoria }),
              this.setState({ resumo: post.resumo }),
              this.setState({ descricao: post.descricao }),
              this.setState({ site: post.site }),
              this.setState({ whatsapp: post.whatsapp }),
              this.setState({ facebook: post.facebook }),
              this.setState({ instagram: post.instagram }),
              this.setState({ youtube: post.youtube }),
              this.setState({ telefone: post.telefone }),
              this.setState({ email: post.email }),
              this.setState({ idioma: post.idioma }),
              this.setState({ valor: post.valor }),
              this.setState({ pais: post.pais }),
              this.setState({ cidade: post.cidade }),
              this.setState({ horario: post.horario }),
              this.setState({ nivelestudo: post.nivelestudo }),
              this.setState({ experiencia: post.experiencia }),
              this.setState({ presencialonline: post.presencialonline })
            ) : (
                <>
                  {/* Nada aqui! */}
                </>
              )
          }
        </>
      )
    })


  }




  //Atualizar post com base na key = this.props.match.params.idEditar 

  atualizar = async (e) => {
    e.preventDefault();
    let posts = firebase2.app.ref('premium');
    const uid = firebase2.getCurrentUid()
    await posts.child(uid).update({

      nome: this.state.nome,
      tipo: this.state.tipo,
      categoria: this.state.categoria,
      titulo: this.state.titulo,
      resumo: this.state.resumo,
      descricao: this.state.descricao,
      image: this.state.url,
      site: this.state.site,
      whatsapp: this.state.whatsapp,
      facebook: this.state.facebook,
      instagram: this.state.instagram,
      youtube: this.state.youtube,
      telefone: this.state.telefone,
      email: this.state.email,
      idioma: this.state.idioma,
      valor: this.state.valor,
      pais: this.state.pais,
      cidade: this.state.cidade,
      horario: this.state.horario,
      nivelestudo: this.state.nivelestudo,
      experiencia: this.state.experiencia,
      presencialonline: this.state.presencialonline,
    });
    alert("¡Publicación editada con éxito!");
    this.props.history.push(`/premium/publication/${this.props.match.params.idEditarPremium}`);
  }


  handleFile = async (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      if (image.type === 'image/png' || image.type === 'image/jpeg') {
        await this.setState({ imagem: image });
        await this.handleUpload();
      } else {
        alert('Envía una imagen PNG o JPG');
        this.setState({ imagem: null });
        return null;
      }
    }
  }

  handleUpload = async () => {
    const { imagem } = this.state;
    const currentUid = firebase2.getCurrentUid();
    const uploadTaks = firebase2.storage
      .ref(`images/${currentUid}/${imagem.name}`)
      .put(imagem);
    await uploadTaks.on('state_changed',
      (snapshot) => {
        //progress
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        //error
        console.log('Error imagem: ' + error);
      },
      () => {
        //sucesso!
        firebase2.storage.ref(`images/${currentUid}`)
          .child(imagem.name).getDownloadURL()
          .then(url => {
            this.setState({ url: url });
          })
      })
  }




  render() {

    let loadingRemoverPremium = this.state.editarValor.map((post) => { return null })

    return (
      <React.Fragment>

        <div className="containerEditarValor" >
          {
            (firebase.auth().currentUser.emailVerified === false) ? (
              <div style={{ paddingTop: "50px", textAlign: "center" }}>
                <Button disabled variant="warning"  >
                  Atención: para tener acceso a esta funcionalidad necesita autenticar su email
              </Button>
              </div>
            ) : (

                (loadingRemoverPremium.length) ? (
                  this.state.editarValor.map((post) => {
                    return (
                      <div key={post.uid}>

                        {
                          (post.chavepost === this.props.match.params.idEditarPremium) ? (


                            // Formulário de submissão para atualizar
                            < Form onSubmit={this.atualizar} id="editarValor" >

                              {/* ícone de retorno */}
                              <Form.Group>
                                <Button href="/dashboard" variant="light" style={{ color: "blue" }} type="submit">
                                  <i className="fas fa-arrow-left"></i>
                                </Button>
                              </Form.Group>


                              <span style={{
                                textAlign: "center", fontFamily: "'Oswald', sans-serif",
                                color: "blue", fontSize: "20px"
                              }}>
                                ¡Esta publicación será editada! <br />
                                <span style={{ color: "black" }}>{post.titulo} </span> <br />
                                {/* <span style={{ color: "black" }}>ID de publicación: </span> {post.key} */}
                              </span>

                              <br />
                              <br />
                              <span style={{ fontFamily: "'Oswald', sans-serif" }}> Atención: Los espacios marcados con * son obligatorios </span>

                              <br />

                              {/* Tipo */}
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label style={{ fontWeight: "bold" }}>Tipo*</Form.Label>
                                <Form.Control
                                  required
                                  as="select"
                                  value={this.state.tipo}
                                  onChange={(e) => this.setState({ tipo: e.target.value })}
                                >
                                  <option>Escuelas</option>
                                  <option>Tiendas</option>
                                  <option>Espacios</option>
                                </Form.Control>
                              </Form.Group>

                              {/* /////////////Categoria Escuelas//////////////////// */}
                              {
                                (this.state.tipo === "Escuelas") ? (
                                  <>

                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                      <Form.Label style={{ fontWeight: "bold" }}>Categoria Escuelas*</Form.Label>
                                      <Form.Control
                                        // defaultValue="Escuelas de Musica"
                                        required
                                        as="select"
                                        value={this.state.categoria}
                                        onChange={(e) => this.setState({ categoria: e.target.value })}
                                      >
                                        <option></option>
                                        <option>Escuelas de Musica</option>
                                        <option>Conservatorio</option>
                                        <option>Escuela Superior de Musica</option>
                                        <option>Escuelas de Musica Online</option>
                                        <option>Escuelas Especiales de Musica</option>

                                      </Form.Control>
                                    </Form.Group>
                                  </>
                                ) : null
                              }

                              {/* /////////////Categoria Tiendas//////////////////// */}
                              {
                                (this.state.tipo === "Tiendas") ? (
                                  <>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                      <Form.Label style={{ fontWeight: "bold" }}>Categoria Tiendas*</Form.Label>
                                      <Form.Control
                                        required
                                        as="select"
                                        value={this.state.categoria}
                                        onChange={(e) => this.setState({ categoria: e.target.value })}
                                      >
                                        <option></option>
                                        <option>Tiendas de Musica</option>
                                        <option>Tiendas de Musica Online</option>
                                        <option>Distribuidores de Musica</option>

                                      </Form.Control>
                                    </Form.Group>
                                  </>
                                ) : null
                              }

                              {/* /////////////Categoria Espacios//////////////////// */}
                              {
                                (this.state.tipo === "Espacios") ? (
                                  <>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                      <Form.Label style={{ fontWeight: "bold" }}>Categoria Espacios*</Form.Label>
                                      <Form.Control
                                        required
                                        as="select"
                                        value={this.state.categoria}
                                        onChange={(e) => this.setState({ categoria: e.target.value })}
                                      >
                                        <option></option>
                                        <option>Espacios de Alquiler</option>
                                        <option>Estudios de Grabacion</option>
                                        <option>Coworking</option>

                                      </Form.Control>
                                    </Form.Group>
                                  </>
                                ) : null
                              }



                              {/* Nome */}
                              <Form.Group>

                                <Form.Label style={{ fontWeight: "bold" }}>Nombre*</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="38"
                                  required
                                  as="textarea" rows={1}
                                  placeholder="Ingrese nome"
                                  autoComplete="off"
                                  value={this.state.nome}
                                  onChange={(e) => this.setState({ nome: e.target.value })}
                                />
                              </Form.Group>

                              {/* Titulo */}
                              <Form.Group>

                                <Form.Label style={{ fontWeight: "bold" }}>Título*</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="53"
                                  required
                                  as="textarea" rows={1}
                                  placeholder="Ingrese título"
                                  autoComplete="off"
                                  value={this.state.titulo}
                                  onChange={(e) => this.setState({ titulo: e.target.value })}
                                />
                              </Form.Group>


                              {/* Imagem e Barra de progresso */}
                              <Form.Group>
                                <Form.Label style={{ fontWeight: "bold" }}>Imagen*</Form.Label>
                                <div className="custom-file">
                                  <Form.File
                                    type="file" onChange={this.handleFile} className="custom-file-input" id="customFileLang" lang="pt"
                                  />
                                  {/* <input required type="file" onChange={this.handleFile} className="custom-file-input" id="customFileLang" lang="pt" /> */}
                                  <label className="custom-file-label" htmlFor="customFileLang">Seleccionar Archivo</label>
                                </div>
                                {() => this.setState({ url: post.imagem })}
                                {this.state.url !== '' ?
                                  <>
                                    <ProgressBar required now={this.state.progress} striped variant="warning" />
                                    <img required src={this.state.url} className="cardImagemEditarValor" alt="Capa do post" />
                                  </>
                                  :
                                  <>
                                    <ProgressBar required now={this.state.progress} striped variant="warning" />
                                    <img required src={post.imagem} className="cardImagemEditarValor" alt="Capa do post" />
                                  </>
                                }
                              </Form.Group>


                              {/* Resumo */}
                              <Form.Group >
                                <Form.Label style={{ fontWeight: "bold" }}>Resumen*</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="110"
                                  required
                                  as="textarea" rows={2}
                                  placeholder="Ingrese resumen"
                                  autoComplete="off"
                                  value={this.state.resumo}
                                  onChange={(e) => this.setState({ resumo: e.target.value })}
                                />
                              </Form.Group>

                              {/* Descrição */}
                              <Form.Group>
                                <Form.Label style={{ fontWeight: "bold" }}>Descripción*</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="3000"
                                  required
                                  as="textarea" rows={4}
                                  placeholder="Ingrese descripción"
                                  autoComplete="off"
                                  value={this.state.descricao}
                                  onChange={(e) => this.setState({ descricao: e.target.value })}
                                />
                              </Form.Group>

                              {/* WebSite */}
                              <Form.Group>
                                <Form.Label>Website</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="1000"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese website"
                                  autoComplete="off"
                                  value={this.state.site}
                                  onChange={(e) => this.setState({ site: e.target.value })}
                                />
                              </Form.Group>

                              {/* Whatsapp */}
                              <Form.Group>
                                <Form.Label>Whatsapp</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="50"
                                  type="number"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese el numero de whatsapp"
                                  autoComplete="off"
                                  value={this.state.whatsapp}
                                  onChange={(e) => this.setState({ whatsapp: e.target.value })}
                                />
                              </Form.Group>

                              {/* Facebook */}
                              <Form.Group>
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="100"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese el nombre de usuario de facebook"
                                  autoComplete="off"
                                  value={this.state.facebook}
                                  onChange={(e) => this.setState({ facebook: e.target.value })}
                                />
                              </Form.Group>

                              {/* Instagram */}
                              <Form.Group>
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="100"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese el nombre de usuario de instagram"
                                  autoComplete="off"
                                  value={this.state.instagram}
                                  onChange={(e) => this.setState({ instagram: e.target.value })}
                                />
                              </Form.Group>

                              {/* Youtube */}
                              <Form.Group>
                                <Form.Label>Youtube</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="100"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese el nombre de usuario de Youtube"
                                  autoComplete="off"
                                  value={this.state.youtube}
                                  onChange={(e) => this.setState({ youtube: e.target.value })}
                                />
                              </Form.Group>

                              {/* Telefone */}
                              <Form.Group>
                                <Form.Label>Teléfono</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="100"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese teléfono"
                                  autoComplete="off"
                                  value={this.state.telefone}
                                  onChange={(e) => this.setState({ telefone: e.target.value })}
                                />
                              </Form.Group>

                              {/* Email */}
                              <Form.Group>
                                <Form.Label style={{ fontWeight: "bold" }}>Email*</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="100"
                                  required
                                  as="textarea" rows={1}
                                  placeholder="Ingrese email"
                                  autoComplete="off"
                                  value={this.state.email}
                                  onChange={(e) => this.setState({ email: e.target.value })}
                                />
                              </Form.Group>

                              {/* Idioma */}
                              <Form.Group>
                                <Form.Label style={{ fontWeight: "bold" }}>Idioma*</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="50"
                                  required
                                  as="textarea" rows={1}
                                  placeholder="Ingrese idioma"
                                  autoComplete="off"
                                  value={this.state.idioma}
                                  onChange={(e) => this.setState({ idioma: e.target.value })}
                                />
                              </Form.Group>

                              {/* Valor */}
                              <Form.Group>
                                <Form.Label>Precio</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="50"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese precio"
                                  autoComplete="off"
                                  value={this.state.valor}
                                  onChange={(e) => this.setState({ valor: e.target.value })}
                                />
                              </Form.Group>

                              {/* País */}
                              {/* <Form.Group>
                                <Form.Label style={{ fontWeight: "bold" }}>País*</Form.Label>
                                <Form.Control
                                  maxlength="23"
                                  required
                                  as="textarea" rows={1}
                                  placeholder="Ingrese País"
                                  autoComplete="off"
                                  value={this.state.pais}
                                  onChange={(e) => this.setState({ pais: e.target.value })}
                                  style={{ color: "blue" }}
                                />
                              </Form.Group> */}

                              {/* Ciudad */}
                              <Form.Group>
                              <Form.Label style={{ fontWeight: "bold" }}>{this.state.pais} <img src={espanaImage} width="40px" alt="Image Espana" /> / Ciudad*</Form.Label>
                                <Form.Control
                                  maxlength="23"
                                  required
                                  as="textarea" rows={1}
                                  placeholder="Ingrese Ciudad"
                                  autoComplete="off"
                                  value={this.state.cidade}
                                  onChange={(e) => this.setState({ cidade: e.target.value })}
                                  style={{ color: "blue" }}
                                />
                              </Form.Group>

                              {/* Horario */}
                              <Form.Group>
                                <Form.Label>Horas de oficina</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="200"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese horarios"
                                  autoComplete="off"
                                  value={this.state.horario}
                                  onChange={(e) => this.setState({ horario: e.target.value })}
                                />
                              </Form.Group>

                              {/* Nivelestudo */}
                              <Form.Group>
                                <Form.Label>Estudios/Formación</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="1000"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese estudios/formación:"
                                  autoComplete="off"
                                  value={this.state.nivelestudo}
                                  onChange={(e) => this.setState({ nivelestudo: e.target.value })}
                                />
                              </Form.Group>

                              {/* Experiencia */}
                              <Form.Group>
                                <Form.Label>Experiencia</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="3000"
                                  as="textarea" rows={1}
                                  placeholder="Ingrese experiencia"
                                  autoComplete="off"
                                  value={this.state.experiencia}
                                  onChange={(e) => this.setState({ experiencia: e.target.value })}
                                />
                              </Form.Group>

                              {/* Presencial / Online */}
                              <Form.Group>
                                <Form.Label style={{ fontWeight: "bold" }}>Presencial / Online*</Form.Label>
                                <Form.Control style={{ color: "blue" }}
                                  maxlength="20"
                                  required
                                  as="textarea" rows={1}
                                  placeholder="Ingrese presencial/online"
                                  autoComplete="off"
                                  value={this.state.presencialonline}
                                  onChange={(e) => this.setState({ presencialonline: e.target.value })}
                                />
                              </Form.Group>



                              <Button variant="primary" type="submit">Actualizar/Editar</Button>

                            </Form>

                          ) : null
                        }
                      </div>

                    )
                  })
                ) : (
                    <div className="loadingEditar" >
                      {
                        (this.props.match.params.idEditarPremium === "null") ? (
                          <a href="/dashboard" style={{ color: "blue", fontSize: "35px", textDecoration: "none" }}>
                            <i className="fas fa-arrow-left"></i> No tienes publicaciones!
                          </a>
                        ) : null
                      }
                      <br />
                      {
                        (this.props.match.params.idEditarPremium !== "null") ? (
                          < span style={{ textAlign: "center" }} >
                            <Spinner animation="grow"
                              variant="primary" style={{ backgroundColor: "#A36920" }} />
                            <br />
                            <p style={{ color: "#A36920" }}>Cargando...</p>
                          </span>
                        ) : null
                      }
                    </div>
                  )
              )
          }
        </div>
      </React.Fragment >
    );
  }
}

export default withRouter(EditarPremium);