import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button, ProgressBar, Spinner } from 'react-bootstrap'
import firebase2 from '../Firebase';
import firebase from 'firebase/app';
import espanaImage from "../img/spain.png"
import './newCSS.css';


const db = firebase.firestore()


class New extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stripe: [],
      tipo: 'Profesionales',
      categoria: 'Cantantes',
      nome: '',
      titulo: '',
      imagem: null,
      url: '',
      resumo: '',
      descricao: '',
      site: '',
      whatsapp: '',
      facebook: '',
      instagram: '',
      youtube: '',
      telefone: '',
      email: '',
      idioma: '',
      valor: '',
      pais: 'España',
      cidade: '',
      horario: '',
      nivelestudo: '',
      experiencia: '',
      presencialonline: '',
      progress: 0,
    };
    this.cadastrar = this.cadastrar.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  //  ---------------------Divisor----------------------- //

  componentDidMount() {

    if (!firebase2.getCurrent()) {
      this.props.history.replace('/');
      return null;
    }

    db.collection('customers')
      .doc(firebase2.getCurrentUid())
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        let state = this.state
        state.stripe = []
        if (!snapshot.empty) {
          snapshot.forEach(async function (doc) {
            state.stripe.push({
              status: doc.data().status,
              role: doc.data().role
            })
          })
        } else (
          state.stripe.push({
            status: "NotActive",
            role: "NotSuscripcion"
          })
        )
        this.setState(state)
      });

  }//Fim componentDidMount

  //  ---------------------Divisor----------------------- //

  // Função cadastrar nova publicação em Database => "posts" 
  cadastrar = async (e) => {

    e.preventDefault();
    let posts = firebase2.app.ref('profesionales');
    let chave = posts.push().key;
    const uid = firebase2.getCurrentUid()
    await posts.child(uid).set({
      chavepost: chave,
      autor: localStorage.nome,
      tipo: this.state.tipo,
      categoria: this.state.categoria,
      nome: this.state.nome,
      titulo: this.state.titulo,
      resumo: this.state.resumo,
      descricao: this.state.descricao,
      image: this.state.url,
      site: this.state.site,
      whatsapp: this.state.whatsapp,
      facebook: this.state.facebook,
      instagram: this.state.instagram,
      youtube: this.state.youtube,
      telefone: this.state.telefone,
      email: this.state.email,
      idioma: this.state.idioma,
      valor: this.state.valor,
      pais: this.state.pais,
      cidade: this.state.cidade,
      horario: this.state.horario,
      nivelestudo: this.state.nivelestudo,
      experiencia: this.state.experiencia,
      presencialonline: this.state.presencialonline,
    });
    alert("¡Publicación enviada!");
    this.props.history.push(`/profesionales/publication/${chave}`);

  }//Fim cadastrar

  //  ---------------------Divisor----------------------- //

  handleFile = async (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      if (image.type === 'image/png' || image.type === 'image/jpeg') {
        await this.setState({ imagem: image });
        await this.handleUpload();
      } else {
        alert('Envía una imagen PNG o JPG');
        this.setState({ imagem: null });
        return null;
      }
    }
  }

  //  ---------------------Divisor----------------------- //

  handleUpload = async () => {
    const { imagem } = this.state;
    const currentUid = firebase2.getCurrentUid();
    const uploadTaks = firebase2.storage
      .ref(`images/${currentUid}/${imagem.name}`)
      .put(imagem);
    await uploadTaks.on('state_changed',
      (snapshot) => {
        //progress
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        //error
        console.log('Error imagem: ' + error);
      },
      () => {
        //sucesso!
        firebase2.storage.ref(`images/${currentUid}`)
          .child(imagem.name).getDownloadURL()
          .then(url => {
            this.setState({ url: url });
          })
      })
  }

  render() {

    let count = null //obrigatoriamente deve ser null
    let statusAssinatura = null
    let roleAssinatura = null

    //Contador de ciclos
    let comprimentoVetor = this.state.stripe.map((post) => {
      return (
        count++
      )

    })

    return (
      <React.Fragment>
        <div className="containerNew">
          {/* Busca por uma assinatura ativa em professionales  */}
          {
            this.state.stripe.map((statustripe) => {
              if (statustripe.status === "active" && statustripe.role === "Suscripción Profesionales") {
                statusAssinatura = "active"
                roleAssinatura = "Suscripción Profesionales"
              }
            })
          }
          {/* Verificar se o email do usuário está autenticado*/}
          {
            (count === comprimentoVetor.length) ? (

              (firebase.auth().currentUser.emailVerified === false) ? (
                <div style={{ paddingTop: "50px", textAlign: "center" }}>
                  <Button disabled variant="warning"  >
                    Atención: para tener acceso a esta funcionalidad necesita autenticar su email
                  </Button>
                </div>
              ) : (
                  <>
                    {(statusAssinatura === "active" && roleAssinatura === "Suscripción Profesionales") ? (
                      <>
                        < Form onSubmit={this.cadastrar} id="new" >
                          {/* ícone de retorno */}
                          < Form.Group >
                            <Button href="/dashboard" variant="light" style={{ color: "green" }} type="submit">
                              <i className="fas fa-arrow-left"></i>
                            </Button>
                          </Form.Group>

                          <span style={{
                            textAlign: "center", fontFamily: "'Oswald', sans-serif",
                            color: "green", fontSize: "30px"
                          }}>
                            Nueva publicación Profesionales
                       </span>
                          <br />
                          <br />
                          <span style={{ fontFamily: "'Oswald', sans-serif" }}> Atención: Los espacios marcados con * son obligatorios </span>

                          <br />


                          {/* /////////////Categoria Profesionales//////////////////// */}



                          <Form.Group controlId="exampleForm.ControlSelect1">
                            {/* <Form.Label style={{ fontWeight: "bold" }}>Categoria Profesionales*</Form.Label> */}
                            <Form.Control
                              defaultValue="Cantantes"
                              required
                              as="select"
                              value={this.state.categoria}
                              onChange={(e) => this.setState({ categoria: e.target.value })}
                              style={{ color: "blue" }}
                            >
                              <option>Cantantes</option>
                              <option>Instrumentistas</option>
                              <option>Grupos Musicales</option>
                              <option>Guitarra Electrica</option>
                              <option>Orquestas</option>
                              <option>Productores</option>
                              <option>Managers</option>
                              <option>Marketing Digital</option>
                              <option>DJ</option>

                            </Form.Control>
                          </Form.Group>

                          {/* ///////////////FIM Categoria Profesionales///////////////// */}


                          {/* Nome */}
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Nombre*</Form.Label>
                            <Form.Control
                              maxlength="38"
                              required
                              as="textarea" rows={1}
                              placeholder="Ingrese Nombre"
                              autoComplete="off"
                              value={this.state.nome}
                              onChange={(e) => this.setState({ nome: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Titulo */}
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Título*</Form.Label>
                            <Form.Control
                              maxlength="53"
                              required
                              as="textarea" rows={2}
                              placeholder="Ingrese título"
                              autoComplete="off"
                              value={this.state.titulo}
                              onChange={(e) => this.setState({ titulo: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Imagem e Barra de progresso */}
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Imagen*</Form.Label>
                            <div className="custom-file">
                              <input required type="file" onChange={this.handleFile} className="custom-file-input" id="customFileLang" lang="pt" />
                              <label className="custom-file-label" htmlFor="customFileLang">Seleccionar Archivo</label>
                            </div>
                            {this.state.url !== '' ?
                              <img required src={this.state.url} className="cardImagemEditar" alt="Capa do post" />
                              :
                              <ProgressBar required now={this.state.progress} striped variant="warning" />
                            }
                          </Form.Group>


                          {/* Resumo */}
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Resumen*</Form.Label>
                            <Form.Control
                              maxlength="110"
                              required
                              as="textarea" rows={2}
                              placeholder="Ingrese resumen"
                              autoComplete="off"
                              value={this.state.resumo}
                              onChange={(e) => this.setState({ resumo: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Descrição */}
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Descripción*</Form.Label>
                            <Form.Control
                              maxlength="3000"
                              required
                              as="textarea" rows={4}
                              placeholder="Ingrese descripción"
                              autoComplete="off"
                              value={this.state.descricao}
                              onChange={(e) => this.setState({ descricao: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* WebSite */}
                          <Form.Group>
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                              maxlength="1000"
                              as="textarea" rows={1}
                              placeholder="Ingrese website"
                              autoComplete="off"
                              value={this.state.site}
                              onChange={(e) => this.setState({ site: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Whatsapp */}
                          <Form.Group>
                            <Form.Label>Whatsapp</Form.Label>
                            <Form.Control
                              maxlength="50"
                              type="number"
                              as="textarea" rows={1}
                              placeholder="Ingrese el numero de whatsapp"
                              autoComplete="off"
                              value={this.state.whatsapp}
                              onChange={(e) => this.setState({ whatsapp: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Facebook */}
                          <Form.Group>
                            <Form.Label>Facebook</Form.Label>
                            <Form.Control
                              maxlength="100"
                              as="textarea" rows={1}
                              placeholder="Ingrese el nombre de usuario de facebook"
                              autoComplete="off"
                              value={this.state.facebook}
                              onChange={(e) => this.setState({ facebook: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Instagram */}
                          <Form.Group>
                            <Form.Label>Instagram</Form.Label>
                            <Form.Control
                              maxlength="100"
                              as="textarea" rows={1}
                              placeholder="Ingrese el nombre de usuario de instagram"
                              autoComplete="off"
                              value={this.state.instagram}
                              onChange={(e) => this.setState({ instagram: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Youtube */}
                          <Form.Group>
                            <Form.Label>Youtube</Form.Label>
                            <Form.Control
                              maxlength="100"
                              as="textarea" rows={1}
                              placeholder="Ingrese el nombre de usuario de Youtube"
                              autoComplete="off"
                              value={this.state.youtube}
                              onChange={(e) => this.setState({ youtube: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Telefone */}
                          <Form.Group>
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control
                              maxlength="100"
                              as="textarea" rows={1}
                              placeholder="Ingrese teléfono"
                              autoComplete="off"
                              value={this.state.telefone}
                              onChange={(e) => this.setState({ telefone: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Email */}
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Email*</Form.Label>
                            <Form.Control
                              maxlength="100"
                              required
                              as="textarea" rows={1}
                              placeholder="Ingrese email"
                              autoComplete="off"
                              value={this.state.email}
                              onChange={(e) => this.setState({ email: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Idioma */}
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Idioma*</Form.Label>
                            <Form.Control
                              maxlength="50"
                              required
                              as="textarea" rows={1}
                              placeholder="Ingrese idioma"
                              autoComplete="off"
                              value={this.state.idioma}
                              onChange={(e) => this.setState({ idioma: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Valor */}
                          <Form.Group>
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                              maxlength="50"
                              as="textarea" rows={1}
                              placeholder="Ingrese precio"
                              autoComplete="off"
                              value={this.state.valor}
                              onChange={(e) => this.setState({ valor: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* País */}
                          {/* <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>País*</Form.Label>
                            <Form.Control
                              maxlength="23"
                              required
                              as="textarea" rows={1}
                              placeholder="Ingrese País"
                              autoComplete="off"
                              value={this.state.pais}
                              onChange={(e) => this.setState({ pais: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group> */}



                          {/* Ciudad */}
                          <Form.Group>
                          
                            <Form.Label style={{ fontWeight: "bold" }}>{this.state.pais} <img src={espanaImage} width="40px" /> / Ciudad*</Form.Label>
                            <Form.Control
                              maxlength="23"
                              required
                              as="textarea" rows={1}
                              placeholder="Ingrese Ciudad"
                              autoComplete="off"
                              value={this.state.cidade}
                              onChange={(e) => this.setState({ cidade: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Horario */}
                          <Form.Group>
                            <Form.Label>Horarios</Form.Label>
                            <Form.Control
                              maxlength="200"
                              as="textarea" rows={1}
                              placeholder="Ingrese horarios"
                              autoComplete="off"
                              value={this.state.horario}
                              onChange={(e) => this.setState({ horario: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Nivelestudo */}
                          <Form.Group>
                            <Form.Label>Estudios/Formación</Form.Label>
                            <Form.Control
                              maxlength="1000"
                              as="textarea" rows={4}
                              placeholder="Ingrese estudios/formación:"
                              autoComplete="off"
                              value={this.state.nivelestudo}
                              onChange={(e) => this.setState({ nivelestudo: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Experiencia */}
                          <Form.Group>
                            <Form.Label>Experiencia</Form.Label>
                            <Form.Control
                              maxlength="2000"
                              as="textarea" rows={4}
                              placeholder="Ingrese experiencia"
                              autoComplete="off"
                              value={this.state.experiencia}
                              onChange={(e) => this.setState({ experiencia: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>

                          {/* Presencial / Online */}
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Presencial / Online*</Form.Label>
                            <Form.Control
                              maxlength="20"
                              required
                              as="textarea" rows={1}
                              placeholder="Ingrese presencial/online"
                              autoComplete="off"
                              value={this.state.presencialonline}
                              onChange={(e) => this.setState({ presencialonline: e.target.value })}
                              style={{ color: "blue" }}
                            />
                          </Form.Group>



                          <Button type="submit" variant="success" >Enviar publicación</Button>

                        </Form>
                      </>
                    ) : (
                        <div style={{
                          paddingTop: "50px", paddingRight: "100px",
                          paddingLeft: "100px", textAlign: "center"
                        }}>

                          <i className="fas fa-exclamation-triangle fa-4x"></i>
                          <br />
                          <br />
                          <Button href="/dashboard" variant="light" style={{ color: "green" }} type="submit">
                            <i className="fas fa-arrow-left"></i>
                          </Button>

                          <h4>
                            Has superado tu límite de publicaciones o no tienes suscripción a infoMUSIC Profesionales.
                            Si aún tiene preguntas, comuníquese con nuestro <a style={{ fontWeight: "bold", textDecoration: "none" }} href="/contactos">soporte</a>.
                          </h4>
                        </div>
                      )
                    }
                  </>
                )
            ) : (
                <div className="loadingNewPost" >
                  <span style={{ textAlign: "center" }} >
                    <Spinner animation="grow"
                      variant="primary" style={{ backgroundColor: "#A36920" }} />
                    <br />
                    <p style={{ color: "#A36920" }}>Cargando...</p>
                  </span>
                </div>
              )
          }
        </div >
      </React.Fragment >
    );
  }
}

export default withRouter(New);