import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from '../Firebase';
import firebaseApp from 'firebase/app'
import './profesoresInfoCSS.css';
import { Button, Carousel } from 'react-bootstrap'
import useWindowDimensions from '../useWindowDimensions.js';

const db = firebaseApp.firestore()

function ProfesoresInfo() {

  const { width } = useWindowDimensions();
  let [profesores, setProfesores] = useState([]);
  let [refreshRender, setRefreshRender] = useState([]);

  useEffect(() => {

    //Ler Banco de dados "profesores" em Realtime Database
    firebase.app.ref('profesores').on('value', (snapshot) => {

      profesores = ([])

      snapshot.forEach((childItem) => {
        //Ler Banco de dados "customers" em Cloud Firestore
        db.collection('customers')
          .doc(childItem.key)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(async (snapshot) => {

            refreshRender = ([]) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)

            if (!snapshot.empty) {
              snapshot.forEach(async function (doc) {
                if (doc.data().status === "active" && doc.data().role === "Suscripción Profesores") {
                  checkSubscription()
                }
              })
            }
            setRefreshRender(refreshRender) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)
          }) //Fim Ler Cloud Firestore

        const checkSubscription = () => {
          profesores.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
            nome: childItem.val().nome,
            imagem: childItem.val().image,
          })
        };

      });
      profesores.reverse();
      setProfesores(profesores);
    });

  }, []); //Fim useEffect


  return (

    <React.Fragment>


      <div className="containerProfesoresInfo" style={{ minHeight: "630px" }}>

        <div style={{ textAlign: "center", backgroundColor: "#DCBA8C" }}>
          <Button style={{
            backgroundColor: "#DCBA8C", color: "white",
            fontFamily: "'Oswald', sans-serif",
            border: "none",
            pointerEvents: "none",
            cursor: "default",
            fontSize: "30px",
          }}
            variant="primary" >
            infoMUSIC  PROFESORES
        </Button>
        </div>
        <br />
        <div style={{ textAlign: "center" }}>
          <p>Encuentra y conecta directamente con tu profesor particular de música.</p>
          <br />
          <p>Gran variedad de instrumentos para aprender:
            canto, guitarra, piano, bateria....etc </p>
          <br />
          <p>Toda la información y facilidades  para empezar:
           nivel, instrumento, experiencia, idioma, pais, precio...</p>
        </div>

        <br />
        <br />
        <div style={(width >= 1366? {paddingLeft:'400px', paddingRight:'400px'}: null)}>
          <Button href={`/profesores/canto clasico`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Canto Clasico
        </Button >
          <Button href={`/profesores/canto moderno`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Canto Moderno
        </Button>
          <Button href={`/profesores/guitarra`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Guitarra
        </Button>
          <Button href={`/profesores/guitarra electrica`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Guitarra Electrica
        </Button>
          <Button href={`/profesores/piano`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Piano
        </Button >
          <Button href={`/profesores/bateria`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Bateria
        </Button>

        </div>

        <br />

        <div style={{ textAlign: "center" }}>
          <i style={{ color: "#D39B5E" }} className="fas fa-user-tie fa-3x" ></i>
          <br />
          <p>PROFESORES</p>
          <br />
        </div >


        <Carousel fade={true} >
          {profesores.map((post) => {
            return (
              <Carousel.Item interval={1000} >
                <img className={(width > 800 ? 'imagemProfesoresInfo' : 'imagemProfesoresInfoMobile')} src={post.imagem} alt="Publication Cover" />
                <Carousel.Caption>
                  <Button href={`/profesores/publication/${post.chavepost}`} style={{ backgroundColor: '#A36920', borderColor: "#A36920" }}>{post.nome}</Button>
                </Carousel.Caption>
              </Carousel.Item>
            )
          })}
        </Carousel>

      </div>

      {/* icon retorno */}
      <div style={{ textAlign: "center", paddingBottom: "20px", paddingTop: "20px" }}>
        <a href="/profesores#">
          <i style={{ color: "#693B00" }} className="fas fa-chevron-up fa-3x "></i>
        </a>
      </div>

    </React.Fragment>
  );
}

export default withRouter(ProfesoresInfo)




