import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import firebase from '../Firebase';
import firebaseApp from 'firebase/app';
import './profesionalesCSS.css';
import { Spinner, Form, FormControl, Button, Card, CardGroup, Container, Row, Col } from 'react-bootstrap'
import useWindowDimensions from '../useWindowDimensions.js';
import logo from '../img/infomusicLogo.png'

const db = firebaseApp.firestore()

function Profesionales() {

  const { width } = useWindowDimensions();
  let [profesionales, setProfesionales] = useState([]);
  let [pesquisa, setPesquisa] = useState("");
  let [pesquisaCidade, setPesquisaCidade] = useState("");
  let { idProfesionales } = useParams();
  let [refreshRender, setRefreshRender] = useState([]);

  useEffect(() => {

    //Ler Banco de dados "profesionales" em Realtime Database
    firebase.app.ref('profesionales').on('value', (snapshot) => {

      profesionales = ([])

      snapshot.forEach((childItem) => {
        //Ler Banco de dados "customers" em Cloud Firestore
        db.collection('customers')
          .doc(childItem.key)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(async (snapshot) => {

            refreshRender = ([]) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)

            if (!snapshot.empty) {
              snapshot.forEach(async function (doc) {
                if (doc.data().status === "active" && doc.data().role === "Suscripción Profesionales") {
                  checkSubscription()
                }
              })
            }
            setRefreshRender(refreshRender) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)
          }) //Fim Ler Cloud Firestore

        const checkSubscription = () => {
          profesionales.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
            nome: childItem.val().nome,
            tipo: childItem.val().tipo,
            titulo: childItem.val().titulo,
            resumo: childItem.val().resumo,
            descricao: childItem.val().descricao,
            imagem: childItem.val().image,
            site: childItem.val().site,
            whatsapp: childItem.val().whatsapp,
            facebook: childItem.val().facebook,
            instagram: childItem.val().instagram,
            telefone: childItem.val().telefone,
            email: childItem.val().email,
            idioma: childItem.val().idioma,
            valor: childItem.val().valor,
            pais: childItem.val().pais,
            cidade: childItem.val().cidade,
            horario: childItem.val().horario,
            nivelestudo: childItem.val().nivelestudo,
            experiencia: childItem.val().experiencia,
            presencialonline: childItem.val().presencialonline,
            youtube: childItem.val().youtube,
            pagamento: childItem.val().pagamento,
            categoria: childItem.val().categoria,
          })
        };

      });
      profesionales.reverse();
      setProfesionales(profesionales);
    });

  }, []); //Fim useEffect



  //Função ler o valor digitado em buscar
  function pesquisar(e) {
    return setPesquisa(e.target.value.toLowerCase())
  }
  //Função ler o valor digitado em buscar (Cidade!)
  function pesquisarCidade(e) {
    return setPesquisaCidade(e.target.value.toLowerCase())
  }

  //Obter tempo de leitura  
  let loadingProfesionales = profesionales.map(() => { return null })


  return (

    <div className={(width >= 1000 ? 'containerProfesionales' : '')}>

      {/* Botões para pesquisa */}
      <Container style={{
        backgroundColor: "#DCBA8C",

      }} >
        <Row>
          <Col sm={3} style={width >= 768 ?
            { textAlign: "left" }
            : { textAlign: "center" }}>
            <Button style={{
              backgroundColor: "#A36920", color: "white",
              fontFamily: "'Oswald', sans-serif",
              border: "none",
              pointerEvents: "none",
              cursor: "default",
              fontSize: "20px",
            }}
              variant="primary" >
              PROFESIONALES {idProfesionales}
            </Button>
          </Col>

          <Col sm={4}
            style={width >= 768 ?
              { textAlign: "right" }
              : { textAlign: "center" }}>
            <Form >
              <FormControl value={pesquisa} style={{ height: "43px", }} type="search"
                placeholder="Buscar por título o nombre del anunciante..."
                aria-label="Search"
                onChange={e => pesquisar(e)}
              />
            </Form>
          </Col>

          <Col sm={4}
            style={width >= 768 ?
              { textAlign: "right" }
              : { textAlign: "center" }}>
            <Form >
              <FormControl value={pesquisaCidade} style={{ height: "43px", }} type="search"
                placeholder="Ingrese el nombre de una ciudad ..."
                aria-label="Search"
                onChange={e => pesquisarCidade(e)}
              />
            </Form>
          </Col>

        </Row>
      </Container>

      <br />
      {/* Verifica se foi carregado o conteúdo do Banco de Dados("profesionales") */}
      {(loadingProfesionales.length) ? (
        <section className="profesionales-cardTeste" >
          {
            <CardGroup className="CardGroupProfesionales" >
              {/* Percorre o banco de dados.  */}
              {
                profesionales.map((post) => {
                  return (
                    <>
                      <div key={post.chavepost} >
                        {
                          ((
                            post.titulo.toLowerCase().indexOf(pesquisa) >= 0
                            || post.nome.toLowerCase().indexOf(pesquisa) >= 0
                            // ||

                          )
                            && post.cidade.toLowerCase().indexOf(pesquisaCidade) >= 0
                            && post.tipo === "Profesionales"
                            && post.categoria.toLowerCase() === idProfesionales.toLowerCase()
                          ) ? (
                            <article className="profesionalesHover">
                              {/* ------Inicio CARD----------------- */}
                              <Card className={(width >= 576 ? 'cardProfesionales' : null)} style={{ backgroundColor: "#DCBA8C" }}>

                                {/* ------Imagem----------------- */}
                                {(post.imagem === '') ? (
                                  <Card.Img
                                    className={(width >= 576 ? 'cardImagemProfesionales' : 'cardImagemProfesionalesMobile')}
                                    src={logo} alt="Capa do post"
                                  />
                                ) : (
                                  <Card.Img
                                    className={(width >= 576 ? 'cardImagemProfesionales' : 'cardImagemProfesionalesMobile')}
                                    src={post.imagem} alt="Capa do post"
                                  />
                                )}
                                {/* ------Titulo----------------- */}
                                <Card.Title className="cardProfesionalesTitulo" style={{ fontSize: "15px", display: "flex" }}>{`${post.titulo}`}</Card.Title>

                                <Card.Body>

                                  {/* ------Nome----------------- */}
                                  <Card.Title className="cardImagemProfesionalesNome">{post.nome}</Card.Title>

                                  {/* ------Pais e Cidade----------------- */}
                                  <Card.Title className="cardImagemProfesionalesPaisCidade" style={{ fontSize: "15px" }}>{post.pais} <br /> {post.cidade}</Card.Title>

                                  {/* ------Resumo----------------- */}
                                  <Card.Text className={(width >= 1000 ? 'cardImagemProfesionalesResumo' : '')} style={{ minHeight: "120px" }}>
                                    {post.resumo}
                                  </Card.Text>
                                  {/* ------+ Informações----------------- */}
                                  <Card.Link href={`/profesionales/publication/${post.chavepost}`}>

                                    <Button style={{
                                      backgroundColor: "#A36920", color: "white",
                                      fontFamily: "'Oswald', sans-serif",
                                      border: "none",
                                      pointerEvents: "none",
                                      cursor: "default",
                                      fontSize: "15px",

                                    }} >+ Información</Button>
                                  </Card.Link>
                                </Card.Body>
                              </Card>
                            </article>
                          ) : null
                        }
                      </div>
                    </>
                  );
                })
              }
            </CardGroup>
          }
        </section >
      ) : (<div className="loadingProfesionales" >
        <span style={{ textAlign: "center" }} >
          <Spinner animation="grow"
            variant="primary" style={{ backgroundColor: "#A36920" }} />
          <br />
          <p style={{ color: "#A36920" }}>Cargando...</p>
        </span>
      </div>
      )
      }
      {/* Ícone para voltar para topo da página */}
      <div style={{ textAlign: "center", paddingBottom: "20px", paddingTop: "20px" }}>
        <a href={`/profesionales/${idProfesionales}#`}>
          <i style={{ color: "#693B00" }} className="fas fa-chevron-up fa-3x "></i>
        </a>
      </div>
    </div >


  )

}

export default withRouter(Profesionales)