import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import auth from 'firebase/app';
import firebase2 from '../Firebase';
import 'firebase/auth';
import './dashboardCSS.css';
import { Form, Button, Card, ListGroup, ListGroupItem, CardColumns, Spinner } from 'react-bootstrap';
import logo from '../img/FondologoinfoMusic.png'
import useWindowDimensions from '../useWindowDimensions.js';

// Propriedades do stripe
import { loadStripe } from '@stripe/stripe-js';
const db = firebase.firestore()
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51IQQZ1J5mkhn9hGETOlBuzxaOhfsfxq4sxz5bBwPxfZVA0lEakWwFcuYz8nSbzYbroCOyOTVTwXvtRR173fOWwyj00pPCnHHj6';
const functionLocation = 'us-central1';

//Variaveis para mostrar solicitação de assinatura
let ShowPedidoAssinaturaProfesores = null
let ShowPedidoAssinaturaProfesionales = null
let ShowPedidoAssinaturaPremium = null

let idParametroChavepostProfesores = null
let idParametroChavepostProfesionales = null
let idParametroChavepostPremium = null

let countKey = 0
let emptyCheckProfesores = undefined
let emptyCheckProfesionales = undefined
let emptyCheckPremium = undefined

function Dashboard() {
  const { width } = useWindowDimensions();
  const [campos, setCampos] = useState({
    nome: localStorage.nome
  });

  let [stripe, setStripe] = useState([]);

  let [profesores, setProfesores] = useState([]);
  let [profesionales, setProfesionales] = useState([]);
  let [premium, setPremium] = useState([]);

  let [subscription1, setSubscription1] = useState('price_1IXqIPJ5mkhn9hGE8c78udOb');
  let [subscription2, setSubscription2] = useState('price_1IXqsRJ5mkhn9hGEg2DtGhWf');

  let [option1, setOption1] = useState('Profesores');
  let [option2, setOption2] = useState('Profesionales');

  let [verificaSeAlgumaAssinaturaAtiva, setVerificaSeAlgumaAssinaturaAtiva] = useState("NotActive");

  const [isLoading, setLoading] = useState(false);

  // Atributos extras de configuração de e-mail
  var actionCodeSettings = {
    url: 'https://www.infomusiconline.com/dashboard'
  }

  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 50000));
  }

  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  const handleClick = () => setLoading(true);

  useEffect(() => {

    if (!firebase2.getCurrent()) {
      return this.props.history.replace('/login');
    }

    firebase2.getUserName((info) => {
      localStorage.nome = info.val().nome;
      setCampos({ nome: localStorage.nome });
    })

    db.collection('customers')
      .doc(firebase2.getCurrentUid())
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {

        if (!snapshot.empty) {
          const documento = snapshot.docs[0];
          setVerificaSeAlgumaAssinaturaAtiva(documento.data().status)
        }

        stripe = ([])
        if (!snapshot.empty) {
          snapshot.forEach(async function (doc) {
            countKey = countKey + 1
            stripe.push({
              childrenKey: countKey,
              uid: firebase2.getCurrentUid(),
              status: doc.data().status,
              role: doc.data().role
            })
          })
        } else (
          stripe.push({
            uid: "NotActive",
            status: "NotActive",
            role: "NotSuscripcion"
          })
        )
        setStripe(stripe)
      });

    //ler banco de dados profesores
    firebase2.app.ref('profesores').on('value', (snapshot) => {
      profesores = ([])
      snapshot.forEach((childItem) => {
        if (childItem.key === firebase2.getCurrentUid()) {
          profesores.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
          })
        }
      });
      profesores.reverse();
      setProfesores(profesores);
    });

    //ler banco de dados profesionales
    firebase2.app.ref('profesionales').on('value', (snapshot) => {
      profesionales = ([])
      snapshot.forEach((childItem) => {
        if (childItem.key === firebase2.getCurrentUid()) {
          profesionales.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
          })
        }
      })
      profesionales.reverse();
      setProfesionales(profesionales);
    });



    //ler banco de dados premium
    firebase2.app.ref('premium').on('value', (snapshot) => {
      premium = ([])
      snapshot.forEach((childItem) => {
        if (childItem.key === firebase2.getCurrentUid()) {
          premium.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
          })
        }
      });
      premium.reverse();
      setPremium(premium);
    });

    firebase2.app.ref('profesores').child(firebase2.getCurrentUid())
      .on("value", function (snapshot) {
        emptyCheckProfesores = snapshot.numChildren()

      })
    firebase2.app.ref('profesionales').child(firebase2.getCurrentUid())
      .on("value", function (snapshot) {
        emptyCheckProfesionales = snapshot.numChildren()

      })
    firebase2.app.ref('premium').child(firebase2.getCurrentUid())
      .on("value", function (snapshot) {
        emptyCheckPremium = snapshot.numChildren()

      })

  }, []);

  // console.log(localStorage.nome)
  async function autenticarEmail() {

    var user = firebase.auth().currentUser

    await user.updateProfile({
      displayName: localStorage.nome,
      // photoURL: "https://example.com/jane-q-user/profile.jpg"
    }).then(function () {
      // console.log("Update successful.") 
    }).catch(function (error) {
      // console.log("An error happened.") 
    });

    await user.sendEmailVerification(actionCodeSettings).then(function () {
      alert(`Correo electrónico de verificación enviado a: ${user.email}`)
    }).catch(function (error) {
      alert(`Hubo un error al enviar el correo electrónico de verificación: ${error}`)
      console.log(error)
    }).finally(function () {

    })
  }


  async function subscribe(specifyProduct) {

    const docRef = await db
      .collection('customers')
      .doc(firebase2.getCurrentUid())
      .collection('checkout_sessions')
      .add({
        price: specifyProduct,
        trial_from_plan: false,
        allow_promotion_codes: true,
        success_url: "https://www.infomusiconline.com/dashboard",
        cancel_url: "https://www.infomusiconline.com/dashboard",
      });

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and 
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
        stripe.redirectToCheckout({ sessionId });
      }
    });

  }

  function handleInputChange1(e) {
    if (e.target.value === 'Profesores') {
      return setSubscription1('price_1IXqIPJ5mkhn9hGE8c78udOb')
    }
    if (e.target.value === 'Profesionales') {
      return setSubscription1('price_1IXqsRJ5mkhn9hGEg2DtGhWf')
    }
    if (e.target.value === 'Premium') {
      return setSubscription1('price_1IXqxAJ5mkhn9hGEREFsbpoK')
    }
  }

  function handleInputChange2(e) {
    if (e.target.value === 'Profesores') {
      return setSubscription2('price_1IXqIPJ5mkhn9hGE8c78udOb')
    }
    if (e.target.value === 'Profesionales') {
      return setSubscription2('price_1IXqsRJ5mkhn9hGEg2DtGhWf')
    }
    if (e.target.value === 'Premium') {
      return setSubscription2('price_1IXqxAJ5mkhn9hGEREFsbpoK')
    }
  }
  async function subscribeCombo15() {

    const docRef = await db
      .collection('customers')
      .doc(firebase2.getCurrentUid())
      .collection('checkout_sessions')
      .add({
        promotion_code: "promo_1IYVqeJ5mkhn9hGE2vstbDm7",
        line_items: [
          {

            price: subscription1, // RECURRING_PRICE_ID
            quantity: 1,

          },
          {

            price: subscription2,
            quantity: 1,

          },
        ],
        success_url: "https://www.infomusiconline.com/dashboard",
        cancel_url: "https://www.infomusiconline.com/dashboard",
      });

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and 
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
        stripe.redirectToCheckout({ sessionId });
      }
    });

  }
  async function subscribeCombo20() {

    const docRef = await db
      .collection('customers')
      .doc(firebase2.getCurrentUid())
      .collection('checkout_sessions')
      .add({
        promotion_code: "promo_1IYY4YJ5mkhn9hGEInfkt3PK",
        line_items: [
          {

            price: 'price_1IXqIPJ5mkhn9hGE8c78udOb',
            quantity: 1,

          },
          {

            price: 'price_1IXqsRJ5mkhn9hGEg2DtGhWf',
            quantity: 1,

          },
          {

            price: 'price_1IXqxAJ5mkhn9hGEREFsbpoK',
            quantity: 1,

          },
        ],
        success_url: "https://www.infomusiconline.com/dashboard",
        cancel_url: "https://www.infomusiconline.com/dashboard",
      });

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and 
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
        stripe.redirectToCheckout({ sessionId });
      }
    });

  }

  async function customerPortal() {
    const functionRef = firebase
      .app()
      .functions(functionLocation)
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: "https://www.infomusiconline.com/dashboard" });
    window.location.assign(data.url);
  }


  return (
    <React.Fragment>
      <div id="dashboard" className="containerDashboard" style={{
        alignItems: "center",
        textAlign: "center",
        justifyContent: 'center',
        justifyItems: "center",
        alignContent: "center"
      }}>

        {profesores.map((post) => {
          idParametroChavepostProfesores = post.chavepost
          return null
        })}

        {profesionales.map((post2) => {
          idParametroChavepostProfesionales = post2.chavepost
          return null
        })}

        {premium.map((post3) => {
          idParametroChavepostPremium = post3.chavepost
          return null
        })}

        < Card style={{ backgroundColor: "#DCBA8C" }} className="dashboard" >
          <Card.Body >
            <Card.Title style={{ color: "black" }}>Hola, <span style={{ color: "#693B00" }}> {campos.nome}.</span>
            </Card.Title>
            <Card.Text style={{ color: "black" }}>
              Conectado con: <b style={{ fontFamily: "'Crimson Pro', serif" }}>{firebase2.getCurrent()}</b>
            </Card.Text>
            {(verificaSeAlgumaAssinaturaAtiva === "NotActive" && firebase.auth().currentUser.emailVerified === true && stripe.length) ? (

              <div style={{ fontSize: "17px", textAlign: "justify", color: "black", fontFamily: "'Crimson Pro', serif" }}>
                <p >
                  Bienvenido a infoMUSIC.
                </p>
                <p>
                  Gracias por confiar en nosotros!
                </p>
                <p>
                  Nuestro principal objetivo es ayudar a todas las personas que participan de
                  Infomusic, puedan ofrecer sus servicios y llegar a su cliente final.
                </p>
                <p>
                  Además de crear una red online que facilite la conexión y la cooperación entre las
                  personas relacionadas con el mundo musical.
                </p>
                <p>
                  Nuestros expertos cuidan de que la difusión y el posicionamiento de nuestros
                  usuarios y artistas estén en un lugar privilegiado a nivel nacional e internacional.
                </p>
                <p>
                  Por una cuota simbólica, por menos de lo que cuestan dos cafés al mes, puedes
                  formar parte de nuestra comunidad.
                </p>
                <p>
                  Elige la opción que te más te interesa:
                </p>

              </div>
            ) : null}

            {verificaSeAlgumaAssinaturaAtiva === "active" ? <>
              <Button className="larguraBotao" style={{
                padding: "10px 20px",
                marginBottom: "10px",
                borderRadius: "4px",
                color: "#FFF",
                fontSize: "15px",
                fontWeight: "bold",
                cursor: "pointer"
              }}
                onClick={() => {
                  customerPortal();
                  handleClick();
                }}
                variant="dark" size="lg" block  >
                {isLoading ? <> Cargando... <Spinner animation="grow" size="sm"
                  variant="primary" style={{ backgroundColor: "#A36920" }} /> <br /></> : 'Acceda a sus datos de facturación'}
              </Button>

              <br />
              <br />
            </> : null}

            {

              (stripe.length) ? (
                (firebase.auth().currentUser.emailVerified === false) ? (
                  <Button onClick={autenticarEmail} variant="warning" >
                    Revisa tu email y haz clic aquí para acceder a tu cuenta
                  </Button>
                ) : (
                  <div>
                    {

                      (firebase.auth().currentUser) ? (
                        <div  >

                          {/* //////////Cards Assinatura/////////// */}

                          <CardColumns>

                            {stripe.map((post2) => {

                              return (

                                <div key={post2.childrenKey}>
                                  {
                                    // Assinatura Ativa Profesores
                                    (post2.status === "active" && post2.role === "Suscripción Profesores") ? (
                                      <Card style={{ paddingTop: "30px" }}>
                                        {ShowPedidoAssinaturaProfesores = true}
                                        <div style={{ backgroundColor: "white" }}>
                                          <i style={{ color: "#693B00" }} className="fas fa-chalkboard-teacher fa-3x"  ></i>
                                          <br />
                                          <span style={{ color: "#693B00", textTransform: "uppercase", fontFamily: "'Hammersmith One', sans-serif" }}>Profesores</span>
                                          <br />
                                          {/* </Button> */}
                                          <Card.Body style={{
                                            alignItems: "center",
                                            textAlign: "center",
                                            justifyContent: 'center',
                                            justifyItems: "center",
                                            alignContent: "center"
                                          }}>

                                            {(emptyCheckProfesores === 0) ? (
                                              <>
                                                <Button className="larguraBotao" style={{
                                                  padding: "10px 20px",
                                                  marginBottom: "10px",
                                                  borderRadius: "4px",
                                                  color: "#FFF",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                  minHeight: "70px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  display: "flex"

                                                }} href="/dashboard/newpostprofesores" variant="success" size="lg" block>
                                                  Nueva publicación PROFESORES
                                                </Button>
                                                <br />
                                                <br />
                                              </>
                                            ) : null}

                                            {(emptyCheckProfesores > 0) ? (
                                              <>
                                                <Button className="larguraBotao" style={{

                                                  padding: "10px 20px",
                                                  marginBottom: "10px",
                                                  borderRadius: "4px",
                                                  color: "#FFF",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                  minHeight: "70px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  display: "flex"

                                                }} href={`/dashboard/editarProfesores/${idParametroChavepostProfesores}`} variant="primary" size="lg" block  >
                                                  Editar publicación PROFESORES
                                                </Button>
                                                <br />
                                                <br />
                                              </>
                                            ) : null}

                                            {/* {(emptyCheckProfesores > 0) ? (
                                              <> */}
                                            <Button className="larguraBotao" style={{
                                              padding: "10px 20px",
                                              marginBottom: "10px",
                                              borderRadius: "4px",
                                              color: "#FFF",
                                              fontSize: "15px",
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              minHeight: "70px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              display: "flex"

                                            }} href="/dashboard/removerprofesores" variant="danger" size="lg" block >
                                              Eliminar publicación PROFESORES
                                                    </Button>
                                            {/* </>
                                            ) : null} */}



                                          </Card.Body>
                                        </div>
                                      </Card>
                                    ) : null
                                  }

                                  {/* -----Divisor-------- */}
                                  {/* -----Divisor-------- */}
                                  {/* -----Divisor-------- */}

                                  {
                                    // Assinatura Ativa Profesionales
                                    (post2.status === "active" && post2.role === "Suscripción Profesionales") ? (
                                      <Card style={{ paddingTop: "30px" }}>
                                        {ShowPedidoAssinaturaProfesionales = true}
                                        <div style={{ backgroundColor: "white" }} >

                                          <i style={{ color: "#693B00" }} className="fas fa-user-tie fa-3x" ></i>
                                          <br />
                                          <span style={{ color: "#693B00", textTransform: "uppercase", fontFamily: "'Hammersmith One', sans-serif" }}>Profesionales</span>
                                          <br />


                                          {/* </Button> */}

                                          <Card.Body style={{
                                            alignItems: "center",
                                            textAlign: "center",
                                            justifyContent: 'center',
                                            justifyItems: "center",
                                            alignContent: "center"
                                          }}>

                                            {(emptyCheckProfesionales === 0) ? (
                                              <>
                                                <Button className="larguraBotao" style={{
                                                  padding: "10px 20px",
                                                  marginBottom: "10px",
                                                  borderRadius: "4px",
                                                  color: "#FFF",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                  minHeight: "70px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  display: "flex"

                                                }} href="/dashboard/newpostprofesionales" variant="success" size="lg" block >
                                                  Nueva publicación PROFESIONALES
                                                </Button>
                                                <br />
                                                <br />
                                              </>
                                            ) : null}

                                            {(emptyCheckProfesionales > 0) ? (
                                              <>
                                                <Button className="larguraBotao" style={{
                                                  padding: "10px 20px",
                                                  marginBottom: "10px",
                                                  borderRadius: "4px",
                                                  color: "#FFF",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                  minHeight: "70px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  display: "flex"
                                                }} href={`/dashboard/editarProfesionales/${idParametroChavepostProfesionales}`} variant="primary" size="lg" block >
                                                  Editar publicación PROFESIONALES
                                                    </Button>

                                                <br />
                                                <br />
                                              </>
                                            ) : null}

                                            {/* {(emptyCheckProfesionales > 0) ? (
                                              <> */}
                                            <Button className="larguraBotao" style={{
                                              padding: "10px 20px",
                                              marginBottom: "10px",
                                              borderRadius: "4px",
                                              color: "#FFF",
                                              fontSize: "15px",
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              minHeight: "70px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              display: "flex"

                                            }} href="/dashboard/removerprofesionales" variant="danger" size="lg" block  >
                                              Eliminar publicación PROFESIONALES
                                        </Button>
                                            {/* </>
                                            ) : null} */}

                                          </Card.Body>
                                        </div>
                                      </Card>
                                    ) : null
                                  }


                                  {/* -----Divisor-------- */}
                                  {/* -----Divisor-------- */}
                                  {/* -----Divisor-------- */}


                                  {
                                    // Assinatura Ativa Premium
                                    (post2.status === "active" && post2.role === "Suscripción Premium") ? (
                                      <Card style={{ paddingTop: "30px" }}>
                                        {ShowPedidoAssinaturaPremium = true}
                                        <div style={{ backgroundColor: "white" }}>
                                          <i style={{ color: "#693B00" }} className="fas fa-school fa-3x"></i>
                                          <i style={{ color: "#693B00", paddingLeft: "10px" }} className="fas fa-store fa-3x"></i>
                                          <i style={{ color: "#693B00", paddingLeft: "10px" }} className="fas fa-door-open fa-3x"></i>
                                        </div>
                                        {/* <br /> */}
                                        <span style={{ color: "#693B00", textTransform: "uppercase", fontFamily: "'Hammersmith One', sans-serif" }}>Premium</span>
                                        <br />


                                        {/* </Button> */}

                                        <Card.Body style={{
                                          alignItems: "center",
                                          textAlign: "center",
                                          justifyContent: 'center',
                                          justifyItems: "center",
                                          alignContent: "center"
                                        }}>

                                          {(emptyCheckPremium === 0) ? (
                                            <>
                                              <Button className="larguraBotao" style={{
                                                padding: "10px 20px",
                                                marginBottom: "10px",
                                                borderRadius: "4px",
                                                color: "#FFF",
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                                minHeight: "70px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                display: "flex"

                                              }} href="/dashboard/newpostpremium" variant="success" size="lg" block  >
                                                Nueva publicación PREMIUM
                                              </Button>
                                              <br />
                                              <br />
                                            </>
                                          ) : null}

                                          {(emptyCheckPremium > 0) ? (
                                            <>
                                              <Button className="larguraBotao" style={{
                                                padding: "10px 20px",
                                                marginBottom: "10px",
                                                borderRadius: "4px",
                                                color: "#FFF",
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                                minHeight: "70px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                display: "flex"
                                              }} href={`/dashboard/editarPremium/${idParametroChavepostPremium}`} variant="primary" size="lg" block  >
                                                Editar publicación PREMIUM
                                              </Button>
                                              <br />
                                              <br />
                                            </>
                                          ) : null}

                                          {/* {(emptyCheckPremium > 0) ? (
                                            <> */}

                                          <Button className="larguraBotao" style={{
                                            padding: "10px 20px",
                                            marginBottom: "10px",
                                            borderRadius: "4px",
                                            color: "#FFF",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                            minHeight: "70px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: "flex"

                                          }} href="/dashboard/removerpremium" variant="danger" size="lg" block>
                                            Eliminar publicación PREMIUM
                                               </Button>
                                          {/* </>
                                          ) : null} */}


                                        </Card.Body>

                                      </Card>
                                    ) : null
                                  }

                                </div>
                              )
                            }
                            )} {/* Fim map stripe */}


                            {/* ///////////////////////Divisor/////////////////////////////// */}
                            {
                              // Solicitação Assinatura Profesores 
                              (ShowPedidoAssinaturaProfesores !== true && stripe.length) ? (
                                < Card style={{ paddingTop: "30px" }}>
                                  <i style={{ color: "#693B00" }} className="fas fa-chalkboard-teacher fa-3x"  ></i>
                                  <br />
                                  <span style={{ color: "#693B00", textTransform: "uppercase", fontFamily: "'Hammersmith One', sans-serif" }}>Profesores</span>
                                  <br />
                                  <br />
                                  <img className="imagemRegister" src={logo} alt="Logo" />
                                  <Card.Body>
                                    <Card.Text>
                                      <span style={{ fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>
                                        {/* ¡Acceso a una publicación mensual o anual! */}
                                        ¡Acceso a una publicación anual!
                                        </span>
                                    </Card.Text>
                                  </Card.Body>
                                  <ListGroup className="list-group-flush">
                                    <ListGroupItem> <p>SUSCRIPCIÓN 19<i className="fas fa-euro-sign"></i>/Año</p></ListGroupItem>
                                    {/* <ListGroupItem> <p>SUSCRIPCIÓN 19<i className="fas fa-euro-sign"></i>/Año  (-20%)</p></ListGroupItem> */}
                                    {/* <ListGroupItem> <p>SUSCRIPCIÓN 1<i className="fas fa-euro-sign"></i>/Mes </p></ListGroupItem> */}
                                  </ListGroup>
                                  <Card.Body >

                                    {/* <Button style={{ background: "#693B00", border: "none" }}
                                      onClick={() => {
                                        subscribe("price_1ISne1J5mkhn9hGEKhUs9bYf");
                                        handleClick();
                                      }} size="lg" block>
                                      {isLoading ? <> Cargando... <Spinner animation="grow"
                                        variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'MENSUAL'}
                                    </Button>
                                    <br />
                                    <br /> */}
                                    <Button style={{ background: "#693B00", border: "none" }}
                                      onClick={() => {
                                        subscribe("price_1IXqIPJ5mkhn9hGE8c78udOb");
                                        handleClick();
                                      }} size="lg" block>
                                      {isLoading ? <> Cargando... <Spinner animation="grow"
                                        variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'Suscripción'}
                                    </Button>

                                  </Card.Body>
                                </Card>
                              ) : null
                            }


                            {/* -----Divisor-------- */}
                            {/* -----Divisor-------- */}
                            {/* -----Divisor-------- */}


                            {
                              // Solicitação Assinatura Profesionales 
                              (ShowPedidoAssinaturaProfesionales !== true && stripe.length) ? (
                                < Card style={{ paddingTop: "30px" }}>
                                  <i style={{ color: "#693B00" }} className="fas fa-user-tie fa-3x" ></i>
                                  <br />
                                  <span style={{ color: "#693B00", textTransform: "uppercase", fontFamily: "'Hammersmith One', sans-serif" }}>Profesionales</span>
                                  <br />
                                  <br />
                                  <img className="imagemRegister" src={logo} alt="Logo" />
                                  <Card.Body>
                                    <Card.Text>
                                      <span style={{ fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>
                                        ¡Acceso a una publicación anual!                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                  <ListGroup className="list-group-flush">
                                    <ListGroupItem> <p>SUSCRIPCIÓN 29<i className="fas fa-euro-sign"></i>/Año</p></ListGroupItem>
                                    {/* <ListGroupItem> <p>SUSCRIPCIÓN 20<i className="fas fa-euro-sign"></i>/Año  (-20%)</p></ListGroupItem> */}
                                    {/* <ListGroupItem> <p>SUSCRIPCIÓN 2<i className="fas fa-euro-sign"></i>/Mes </p></ListGroupItem> */}
                                  </ListGroup>
                                  <Card.Body>

                                    {/* <Button style={{ background: "#693B00", border: "none" }}
                                      onClick={() => {
                                        subscribe("price_1ISnePJ5mkhn9hGEwv2h56Et");
                                        handleClick();
                                      }} size="lg" block >
                                      {isLoading ? <> Cargando... <Spinner animation="grow"
                                        variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'MENSUAL'}
                                    </Button>
                                    <br />
                                    <br /> */}
                                    <Button style={{ background: "#693B00", border: "none" }}
                                      onClick={() => {
                                        subscribe("price_1IXqsRJ5mkhn9hGEg2DtGhWf");
                                        handleClick();
                                      }} size="lg" block>
                                      {isLoading ? <> Cargando... <Spinner animation="grow"
                                        variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'Suscripción'}
                                    </Button>

                                  </Card.Body>
                                </Card>
                              ) : null
                            }

                            {/* -----Divisor-------- */}
                            {/* -----Divisor-------- */}
                            {/* -----Divisor-------- */}

                            {
                              // Solicitação Assinatura Premium 
                              (ShowPedidoAssinaturaPremium !== true && stripe.length) ? (
                                <Card style={{ paddingTop: "30px" }}>
                                  <div style={{ backgroundColor: "white" }}>
                                    <i style={{ color: "#693B00" }} className="fas fa-school fa-3x"></i>
                                    <i style={{ color: "#693B00", paddingLeft: "15px" }} className="fas fa-store fa-3x"></i>
                                    <i style={{ color: "#693B00", paddingLeft: "15px" }} className="fas fa-door-open fa-3x"></i>
                                  </div>
                                  {(width < 500) ? (<br />) : null}
                                  <span style={{ color: "#693B00", textTransform: "uppercase", fontFamily: "'Hammersmith One', sans-serif" }}>Premium</span> <span style={{ fontSize: "14px" }}>(Escuelas, Tiendas y Espacios)</span>
                                  <br />
                                  <br />
                                  <img className="imagemRegister" src={logo} alt="Logo" />
                                  <Card.Body>

                                    <Card.Text>
                                      <span style={{ fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>
                                        ¡Acceso a una publicación anual!
                                               </span>
                                    </Card.Text>
                                  </Card.Body>
                                  <ListGroup className="list-group-flush">
                                    <ListGroupItem> <p>SUSCRIPCIÓN 39<i className="fas fa-euro-sign"></i>/Año</p></ListGroupItem>
                                    {/* <ListGroupItem> <p>SUSCRIPCIÓN 30<i className="fas fa-euro-sign"></i>/Año  (-20%)</p></ListGroupItem>
                                    <ListGroupItem> <p>SUSCRIPCIÓN 3<i className="fas fa-euro-sign"></i>/Mes </p></ListGroupItem> */}
                                  </ListGroup>
                                  <Card.Body >

                                    {/* <Button style={{ background: "#693B00", border: "none" }}
                                      onClick={() => {
                                        subscribe("price_1ISneXJ5mkhn9hGE7WSTcubL");
                                        handleClick();
                                      }} size="lg" block>
                                      {isLoading ? <> Cargando... <Spinner animation="grow"
                                        variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'MENSUAL'}
                                    </Button>
                                    <br />
                                    <br /> */}
                                    <Button style={{ background: "#693B00", border: "none" }}
                                      onClick={() => {
                                        subscribe("price_1IXqxAJ5mkhn9hGEREFsbpoK");
                                        handleClick();
                                      }} size="lg" block>
                                      {isLoading ? <> Cargando... <Spinner animation="grow"
                                        variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'Suscripción'}
                                    </Button>

                                  </Card.Body>

                                </Card>
                              ) : null
                            }

                          </CardColumns>



                          <CardColumns>

                            {
                              // Solicitação Combo 1
                              (stripe.length) ? (
                                <Card style={{ paddingTop: "30px" }}>

                                  {(width < 500) ? (<br />) : null}
                                  <span style={{ color: "#693B00", textTransform: "uppercase", fontFamily: "'Hammersmith One', sans-serif" }}>Combo para 2 suscripciones</span>
                                  <br />
                                  <br />
                                  <img className="imagemRegister" src={logo} alt="Logo" />
                                  <Card.Body>

                                    {/* <Card.Text>
          <span style={{ fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>
            ¡Acceso a una publicación anual!
                   </span>
        </Card.Text> */}
                                  </Card.Body>
                                  <ListGroup className="list-group-flush">
                                    <ListGroupItem> <p>15% (del total)</p></ListGroupItem>
                                  </ListGroup>
                                  <Card.Body >

                                    <span>Opción 1</span>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                      {/* <Form.Label style={{ fontWeight: "bold" }}>Categoria Profesores*</Form.Label> */}
                                      <Form.Control
                                        defaultValue="Cantantes"
                                        required
                                        as="select"
                                        value={option1}
                                        onChange={e => { handleInputChange1(e); setOption1(e.target.value) }}
                                        style={{ color: "blue" }}
                                      >
                                        {!ShowPedidoAssinaturaProfesores ? <option>Profesores</option> : null}
                                        {!ShowPedidoAssinaturaProfesionales ? <option>Profesionales</option> : null}
                                        {!ShowPedidoAssinaturaPremium ? <option>Premium</option> : null}
                                      </Form.Control>

                                    </Form.Group>

                                    <span>Opción 2</span>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                      {/* <Form.Label style={{ fontWeight: "bold" }}>Categoria Profesores*</Form.Label> */}
                                      <Form.Control
                                        defaultValue="Cantantes"
                                        required
                                        as="select"
                                        value={option2}
                                        onChange={e => { handleInputChange2(e); setOption2(e.target.value) }}
                                        style={{ color: "blue" }}
                                      >
                                        {!ShowPedidoAssinaturaProfesores ? <option>Profesores</option> : null}
                                        {!ShowPedidoAssinaturaProfesionales ? <option>Profesionales</option> : null}
                                        {!ShowPedidoAssinaturaPremium ? <option>Premium</option> : null}
                                      </Form.Control>

                                    </Form.Group>

                                    {((!ShowPedidoAssinaturaProfesores && !ShowPedidoAssinaturaProfesionales)
                                      || (!ShowPedidoAssinaturaProfesores && !ShowPedidoAssinaturaPremium)
                                      || (!ShowPedidoAssinaturaProfesionales && !ShowPedidoAssinaturaPremium)
                                    ) ? (

                                      (subscription1 === subscription2) ?
                                        (
                                          <><span>Seleccione diferentes opciones</span></>
                                        ) :
                                        <>
                                          < Button style={{ background: "#693B00", border: "none" }}
                                            onClick={() => {
                                              subscribeCombo15();
                                              handleClick();
                                            }} size="lg" block>
                                            {isLoading ? <> Cargando... <Spinner animation="grow"
                                              variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'Suscripción'}
                                          </Button>
                                          <span style={{ fontSize: "19px", fontFamily: "'Oswald', sans-serif", color: "green" }}>
                                            Atención: promoción válida solo para la compra de 2 suscripciones simultáneas
                                        </span>
                                        </>

                                    ) : (
                                      <>
                                        <span style={{ fontSize: "19px", fontFamily: "'Oswald', sans-serif", color: "green" }}>
                                          Verificamos que tienes 2 o más suscripciones activas, nuestra promoción es válida solo para la compra de 2 suscripciones simultáneas.
                                          </span><br /><br />
                                      </>
                                    )
                                    }
                                    {/* 
                                    {subscription1 === subscription2 ?
                                      <><span>Seleccione diferentes opciones</span></> :
                                      <>
                                        < Button style={{ background: "#693B00", border: "none" }}
                                          onClick={() => {
                                            subscribeCombo15();
                                            handleClick();
                                          }} size="lg" block>
                                          {isLoading ? <> Cargando... <Spinner animation="grow"
                                            variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'Suscripción'}
                                        </Button>
                                        <span style={{ fontSize: "19px", fontFamily: "'Oswald', sans-serif", color: "green" }}>
                                          Atención: promoción válida solo para la compra de 2 suscripciones simultáneas
                                        </span>
                                      </>
                                    } */}


                                  </Card.Body>

                                </Card>
                              ) : null
                            }
                            {
                              // Solicitação Combo 2
                              (stripe.length) ? (
                                <Card style={{ paddingTop: "30px" }}>

                                  {(width < 500) ? (<br />) : null}
                                  <span style={{ color: "#693B00", textTransform: "uppercase", fontFamily: "'Hammersmith One', sans-serif" }}>Combo para 3 suscripciones</span>
                                  <br />
                                  <br />
                                  <img className="imagemRegister" src={logo} alt="Logo" />
                                  <Card.Body>

                                    {/* <Card.Text>
          <span style={{ fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>
            ¡Acceso a una publicación anual!
                   </span>
        </Card.Text> */}
                                  </Card.Body>
                                  <ListGroup className="list-group-flush">
                                    <ListGroupItem> <p>20% (del total)</p></ListGroupItem>
                                  </ListGroup>
                                  <Card.Body >

                                    <span style={{ fontSize: "19px", fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>Profesores</span><br /><br />
                                    <span style={{ fontSize: "19px", fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>Profesionales</span><br /><br />
                                    <span style={{ fontSize: "19px", fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>Premium</span><br /><br />



                                    {!ShowPedidoAssinaturaProfesores || !ShowPedidoAssinaturaProfesores || !ShowPedidoAssinaturaProfesores ?
                                      <>
                                        < Button style={{ background: "#693B00", border: "none" }}
                                          onClick={() => {
                                            subscribeCombo20();
                                            handleClick();
                                          }} size="lg" block>
                                          {isLoading ? <> Cargando... <Spinner animation="grow"
                                            variant="primary" size="sm" style={{ backgroundColor: "#A36920" }} /> </> : 'Suscripción'}
                                        </Button>
                                        <span style={{ fontSize: "19px", fontFamily: "'Oswald', sans-serif", color: "green" }}>
                                          Atención: promoción válida solo para la compra de 3 suscripciones simultáneas
                                        </span>
                                      </>
                                      : <>
                                        <span style={{ fontSize: "19px", fontFamily: "'Oswald', sans-serif", color: "green" }}>
                                          Verificamos que tienes 1 o más suscripciones activas, nuestra promoción es válida solo para la compra de 3 suscripciones simultáneas.
                                          </span><br /><br />
                                      </>
                                    }



                                  </Card.Body>

                                </Card>
                              ) : null
                            }
                          </CardColumns>



                        </div>
                      ) : (
                        <>
                          <Button disabled>El usuario debe iniciar sesión!</Button>

                        </>
                      )}

                  </div>
                )
              ) : (

                <div className="loadingProfesionales" >
                  <span style={{ textAlign: "center" }} >
                    <Spinner animation="grow"
                      variant="primary" style={{ backgroundColor: "#A36920" }} />
                    <br />
                    <p style={{ color: "#A36920" }}>Cargando...</p>
                  </span>
                </div>
              )
            }

          </Card.Body>
        </Card>
      </div >
    </React.Fragment >
  )
}

export default withRouter(Dashboard);






