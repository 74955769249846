import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import firebase from './Firebase'

// *********************Components*********************
import Home from './home/homeJS'
import Header from './header/headerJS'
import Footer from './footer/footerJS'
import Contatos from './contatos/contatosJS'
import Sobre from './sobre/sobreJS'
import Dashboard from './dashboard/dashboardJS'
import Login from './login/loginJS'
import Register from './register/registerJS'
import RecuperarSenha from './recuperarSenha/recuperarSenhaJS'
import PoliticaPrivacidad from './politicaPrivacidad/politicaPrivacidadJS'
import InfoMusic from './sobre/infoMusicJS'

import NewProfesionales from './new/newProfesionalesJS'
import NewProfesores from './new/newProfesoresJS'
import NewPremium from './new/newPremiumJS'

import RemoverProfesionales from './remover/removerProfesionalesJS'
import RemoverPremium from './remover/removerPremiumJS'
import RemoverProfesores from './remover/removerProfesoresJS'

import EditarProfesores from './editar/editarProfesoresJS'
import EditarProfesionales from './editar/editarProfesionalesJS'
import EditarPremium from './editar/editarPremiumJS'

import Profesores from './profesores/profesoresJS'
import Profesionales from './profesionales/profesionalesJS'
import Premium from './premium/premiumJS'

import ProfesoresInfo from './profesores/profesoresInfoJS'
import ProfesionalesInfo from './profesionales/profesionalesInfoJS'
import EscuelasInfo from './escuelas/escuelasInfoJS'
import TiendasInfo from './tiendas/tiendasInfoJS'
import EspaciosInfo from './espacios/espaciosInfoJS'

import ProfesoresAnuncio from './profesores/profesoresAnuncioJS'
import ProfesionalesAnuncio from './profesionales/profesionalesAnuncioJS'
import PremiumAnuncio from './premium/premiumAnuncioJS'

import './App.css';

class App extends React.Component {

  state = {
    firebaseInitialized: false
  };

  componentDidMount() {
    firebase.isInitialized().then(resultado => {
      // Devolve o usuario
      this.setState({ firebaseInitialized: resultado });
    })
  }
  render() {
    return this.state.firebaseInitialized !== false ? (
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/recuperarcontrasena" component={RecuperarSenha} />
          <Route exact path="/politicaprivacidad" component={PoliticaPrivacidad} />
          <Route exact path="/infomusic" component={InfoMusic} />
          <Route exact path="/acerca" component={Sobre} />
          <Route exact path="/contactos" component={Contatos} />

          <Route exact path="/dashboard" component={Dashboard} />

          <Route exact path="/dashboard/newpostprofesores" component={NewProfesores} />
          <Route exact path="/dashboard/newpostprofesionales" component={NewProfesionales} />
          <Route exact path="/dashboard/newpostpremium" component={NewPremium} />

          <Route exact path="/dashboard/removerprofesores" component={RemoverProfesores} />
          <Route exact path="/dashboard/removerprofesionales" component={RemoverProfesionales} />
          <Route exact path="/dashboard/removerpremium" component={RemoverPremium} />

          <Route exact path="/dashboard/editarProfesores/:idEditarProfesores" component={EditarProfesores} />
          <Route exact path="/dashboard/editarProfesionales/:idEditarProfesionales" component={EditarProfesionales} />
          <Route exact path="/dashboard/editarPremium/:idEditarPremium" component={EditarPremium} />

          <Route exact path="/profesores" component={ProfesoresInfo} />
          <Route exact path="/profesionales" component={ProfesionalesInfo} />
          <Route exact path="/escuelas" component={EscuelasInfo} />
          <Route exact path="/tiendas" component={TiendasInfo} />
          <Route exact path="/espacios" component={EspaciosInfo} />


          <Route exact path="/profesores/:idProfesores" component={Profesores} />
          <Route exact path="/profesionales/:idProfesionales" component={Profesionales} />
          <Route exact path="/premium/:idPremium" component={Premium} />


          <Route exact path="/profesores/publication/:idProfesoresAnuncio" component={ProfesoresAnuncio} />
          <Route exact path="/profesionales/publication/:idProfesionalesAnuncio" component={ProfesionalesAnuncio} />
          <Route exact path="/premium/publication/:idPremiumAnuncio" component={PremiumAnuncio} />


        </Switch>
        <Footer />
      </BrowserRouter>

    ) : null
  }
}

export default App;
