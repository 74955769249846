import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button, Card, ListGroup, ListGroupItem, CardColumns } from 'react-bootstrap'
import firebase from '../Firebase';
import firebase2 from 'firebase/app';
import './registerCSS.css';
import 'firebase/auth';
import logo from '../img/FondologoinfoMusic.png'

class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      emailusuario: '',
      email: '',
      password: ''
    };

    this.register = this.register.bind(this);
    this.onRegister = this.onRegister.bind(this);

  }

  register(e) {
    e.preventDefault();

    this.onRegister();
  }

  onRegister = async () => {
    try {
      const { nome, emailusuario, email, password } = this.state;

      await firebase.register(nome, emailusuario, email, password);
      this.props.history.replace('/dashboard');
      window.location.reload();
    } catch (error) {
      alert(error.message);
    }
  }

  render() {

    return (
      <React.Fragment>

        <div className="containerRegister">

          {(!firebase2.auth().currentUser) ? (

            <Form onSubmit={this.register} id="register">
              <h1 style={{ fontFamily: "'Oswald', sans-serif" }}
                className="register-h1">REGISTRATE</h1>
              <span style={{ fontFamily: "'Oswald', sans-serif" }}> Entre en tu email para activar tu cuenta. </span>
              <br />
              <Form.Group >
                <Form.Label>Nombre y Apellidos*</Form.Label>
                <Form.Control maxLength="100"
                  required type="text" value={this.state.nome} autoComplete="off"
                  onChange={(e) => this.setState({ nome: e.target.value })} placeholder="Enter Nombre" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>E-mail*</Form.Label>
                <Form.Control
                  maxLength="100"
                  required
                  type="email" placeholder="Enter email"
                  autoComplete="off" value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Contraseña*</Form.Label>
                <Form.Control
                  maxLength="100"
                  required
                  type="password" placeholder="Enter Password"
                  autoComplete="off" value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
                <span style={{ fontFamily: "'Oswald', sans-serif" }}> Atención: Los espacios marcados con * son obligatorios </span>
              </Form.Group>

              <Button type="submit">Registrar</Button>

            </Form>

          ) : null
          }


          <div className="registerSuscripcion" >

            <CardColumns  >
              <Card style={{ paddingTop: "30px", minHeight: "470px" }} >
                <p style={{ fontFamily: "'Oswald', sans-serif", color: "#693B00", textTransform: "uppercase" }}>
                  Profesores</p>
                <br />
                <br />
                <img className="imagemRegister" src={logo} alt="Logo" />
                <Card.Body>
                  <Card.Text style={{ fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>
                    ¡Acceso a una publicación anual!
                    {/* ¡Acceso a una publicación mensual o anual! */}
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroupItem> <p>SUSCRIPCIÓN 19<i className="fas fa-euro-sign"></i>/Año</p></ListGroupItem>
                  {/* <ListGroupItem> <p>SUSCRIPCIÓN 10<i className="fas fa-euro-sign"></i>/Año  (-20%)</p></ListGroupItem>
                  <ListGroupItem> <p>SUSCRIPCIÓN 1<i className="fas fa-euro-sign"></i>/Mes </p></ListGroupItem> */}
                </ListGroup>

              </Card>

              < Card style={{ paddingTop: "30px", minHeight: "470px" }} >
                <p style={{ fontFamily: "'Oswald', sans-serif", color: "#693B00", textTransform: "uppercase" }}>
                  Profesionales</p>
                <br />
                <br />
                <img className="imagemRegister" src={logo} alt="Logo" />
                <Card.Body>
                  <Card.Text style={{ fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>
                    ¡Acceso a una publicación anual!
                    {/* ¡Acceso a una publicación mensual o anual! */}
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroupItem> <p>SUSCRIPCIÓN 29<i className="fas fa-euro-sign"></i>/Año</p></ListGroupItem>
                  {/* <ListGroupItem> <p>SUSCRIPCIÓN 20<i className="fas fa-euro-sign"></i>/Año  (-20%)</p></ListGroupItem>
                  <ListGroupItem> <p>SUSCRIPCIÓN 2<i className="fas fa-euro-sign"></i>/Mes </p></ListGroupItem> */}
                </ListGroup>
              </Card >
              <Card style={{ paddingTop: "30px", minHeight: "470px" }} >
                <p style={{ fontFamily: "'Oswald', sans-serif", color: "#693B00", textTransform: "uppercase" }}>
                  Premium</p>
                <br />
                  (Escuelas, Tiendas y Espacios)
                  <br />
                <br />
                <img className="imagemRegister" src={logo} alt="Logo" />
                <Card.Body>
                  <Card.Text style={{ fontFamily: "'Oswald', sans-serif", color: "#A36920" }}>
                    ¡Acceso a una publicación anual!
                    {/* ¡Acceso a una publicación mensual o anual! */}
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroupItem> <p>SUSCRIPCIÓN 39<i className="fas fa-euro-sign"></i>/Año</p></ListGroupItem>
                  {/* <ListGroupItem> <p>SUSCRIPCIÓN 30<i className="fas fa-euro-sign"></i>/Año  (-20%)</p></ListGroupItem>
                  <ListGroupItem> <p>SUSCRIPCIÓN 3<i className="fas fa-euro-sign"></i>/Mes </p></ListGroupItem> */}
                </ListGroup>
              </Card>
            </CardColumns>
          </div>


        </div>
      </React.Fragment >
    );
  }
}

export default withRouter(Register);