import React from 'react';
import { Card, CardColumns } from 'react-bootstrap'

// *********************CSS and Images*********************
import './homeCSS.css';



function Home() {

  return (
    <div className="containerHome">

      {/*---------------- Serviços --------------*/}

      <div className="textTopHome">
        <h2 style={{ fontFamily: "'Oswald', sans-serif", color: "#693B00" }}> Qué necesitas? </h2>
      </div>

      <div className="cardHome">
        <CardColumns >

          <Card style={{ alignItems: "center", textAlign: "center", padding: "5%" }} >
            <i style={{ color: "#D39B5E" }} className="fas fa-chalkboard-teacher fa-3x"  ></i>
            <Card.Body>
              <Card.Title>PROFESORES</Card.Title>
              <Card.Text className="descricaoHome" style={{ fontFamily: "'Yanone Kaffeesatz', sans-serif", fontSize: "18px" }}>
                Gran variedad de instrumentos para aprender: canto, guitarra, piano, bateria...
                </Card.Text>
              <a href="/profesores" style={{ backgroundColor: "#D39B5E", border: "none" }} className="btn btn-primary stretched-link">Mas información</a>
            </Card.Body>
          </Card>

          <Card style={{ alignItems: "center", textAlign: "center", padding: "5%" }}>
            <i style={{ color: "#D39B5E" }} className="fas fa-store fa-3x"></i>
            <Card.Body>
              <Card.Title>TIENDAS</Card.Title>
              <Card.Text className="descricaoHome" style={{ fontFamily: "'Yanone Kaffeesatz', sans-serif", fontSize: "18px" }}>
                Tiendas, tiendas online, distribuidores...
                </Card.Text>
              <a href="/tiendas" style={{ backgroundColor: "#D39B5E", border: "none" }} className="btn btn-primary stretched-link">Mas información</a>
            </Card.Body>
          </Card>

          <Card style={{ alignItems: "center", textAlign: "center", padding: "5%" }}>
            <i style={{ color: "#D39B5E" }} className="fas fa-user-tie fa-3x" ></i>
            <Card.Body>
              <Card.Title>PROFESIONALES</Card.Title>
              <Card.Text className="descricaoHome" style={{ fontFamily: "'Yanone Kaffeesatz', sans-serif", fontSize: "18px" }}>
                Profesionales de todas las areas relacionadas con la música: artístas, cantantes, instrumentistas, productores, grupos musicales, DJ...
                </Card.Text>
              <a href="/profesionales" style={{ backgroundColor: "#D39B5E", border: "none" }} className="btn btn-primary stretched-link">Mas información</a>
            </Card.Body>
          </Card>

          <Card style={{ alignItems: "center", textAlign: "center", padding: "5%" }}>
            <i style={{ color: "#D39B5E" }} className="fas fa-door-open fa-3x"></i>
            <Card.Body>
              <Card.Title>ESPACIOS</Card.Title>
              <Card.Text className="descricaoHome" style={{ fontFamily: "'Yanone Kaffeesatz', sans-serif", fontSize: "18px" }}>
                Encuentra el espacio que necesitas en cada momento para tu actividad profesional o amante de la musica...</Card.Text>
              <a href="/espacios" style={{ backgroundColor: "#D39B5E", border: "none" }} className="btn btn-primary stretched-link">Mas información</a>
            </Card.Body>
          </Card>

          <Card style={{ alignItems: "center", textAlign: "center", padding: "5%" }}>
            <i style={{ color: "#D39B5E" }} className="fas fa-school fa-3x"></i>
            <Card.Body>
              <Card.Title>ESCUELAS</Card.Title>
              <Card.Text className="descricaoHome" style={{ fontFamily: "'Yanone Kaffeesatz', sans-serif", fontSize: "18px" }}>
                Escuelas de música, conservatorios, escuelas superiores de música, escuelas de música especiales, online, oficiales, extranjeras... </Card.Text>
              <a href="/escuelas" style={{ backgroundColor: "#D39B5E", border: "none" }} className="btn btn-primary stretched-link">Mas información</a>
            </Card.Body>
          </Card>

          <Card style={{ alignItems: "center", textAlign: "center", padding: "5%" }} >
            <i style={{ color: "#D39B5E" }} className="fas fa-music fa-3x"></i>
            <Card.Body>
              <Card.Title>QUE ES infoMUSIC?</Card.Title>
              <Card.Text className="descricaoHome" style={{ fontFamily: "'Yanone Kaffeesatz', sans-serif", fontSize: "18px" }}>
                Tu web para ofrecer y encontrar todo lo que necesitas para avanzar en la música...</Card.Text>
              <a href="/infomusic" style={{ backgroundColor: "#D39B5E", border: "none" }} className="btn btn-primary stretched-link">Mas información</a>
            </Card.Body>
          </Card>
        </CardColumns>
      </div>

      {/* icon retorno */}
      <div style={{ textAlign: "center", paddingBottom: "20px", paddingTop: "20px" }}>
        <a href="/#">
          <i style={{ color: "#693B00" }} className="fas fa-chevron-up fa-3x "></i>
        </a>
      </div>

    </div >
  );
}

export default Home;