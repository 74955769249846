import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './footerCSS.css';
import useWindowDimensions from '../useWindowDimensions.js';
import logo from '../img/infomusicLogo.png'
import medalla from '../img/medalla.png'

function Footer() {
  const { width } = useWindowDimensions();
  return (

    <footer className="page-footer font-small special-color-dark pt-4 containerFooter" >

      <Container style={{ backgroundColor: "#DCBA8C", paddingTop: "" }}>
        <Row>
          <Col className="logoFooter"  >
            <div >
              <a href="/" >
                <img className={(width >= 1000 ? 'imagemFooter' : 'imagemFooterMobile')} src={logo} alt="Logo Footer"></img>
              </a>
            </div>
          </Col>
          <Col style={{ textAlign: 'right' }} >
            <div>
              <a rel="noreferrer" target="_blank" href="https://lasolidariadautor.org" >
                <img className={(width >= 1000 ? 'imagemMedalla' : 'imagemMedallaMobile')} src={medalla} alt="Medalla Solidaria "></img>
              </a>
            </div>
          </Col>
        </Row>

        {(width >= 1000) ? (
          <Row style={{ paddingTop: "50px", paddingBottom: "30px" }}>
            <Col style={{ textAlign: "left", }}>
              <div style={{ paddingBottom: "10px" }} >
                <a rel="noreferrer" target="_blank" href="https://www.facebook.com/infomusiconline" className="btn-floating btn-fb mx-1">
                  <i style={{ color: 'white' }} className="fab fa-facebook-f fa-2x"> </i>
                </a>
                <a rel="noreferrer" target="_blank" href="https://twitter.com" className="btn-floating btn-tw mx-1">
                  <i style={{ color: "white" }} className="fab fa-twitter fa-2x"> </i>
                </a>
                <a rel="noreferrer" target="_blank" href="https://www.instagram.com/infomusic.online" className="btn-floating btn-li mx-1">
                  <i style={{ color: "white" }} className="fab fa-instagram fa-2x"> </i>
                </a>
                <a rel="noreferrer" target="_blank" href="https://www.youtube.com/" className="btn-floating btn-li mx-1">
                  <i style={{ color: "white" }} className="fab fa-youtube fa-2x"> </i>
                </a>
              </div>
            </Col>
            <Col style={{ textAlign: "center", paddingTop: "12px" }} >
              <a href="/acerca" style={{ textDecoration: "none", fontFamily: "'Oswald', sans-serif", color: "white" }}>Acerca</a>
            </Col>
            <Col style={{ textAlign: "center", paddingTop: "12px" }} >
              <a href="/infomusic" style={{ textDecoration: "none", fontFamily: "'Oswald', sans-serif", color: "white" }}>Que és infoMUSIC?</a>
            </Col>
            <Col style={{ textAlign: "center", paddingTop: "12px" }} >
              <a href="/politicaprivacidad" style={{ textDecoration: "none", fontFamily: "'Oswald', sans-serif", color: "white" }}>Política de privacidad</a>
            </Col>
            <Col style={{ textAlign: "center", paddingTop: "12px" }} >
              <a href="/contactos" style={{ textDecoration: "none", fontFamily: "'Oswald', sans-serif", color: "white" }}>Contacto</a>
            </Col>
          </Row>
        ) : (
          <>
            <Row style={{ paddingTop: "50px", }}>
              <Col style={{ textAlign: "center" }}>
                <div className="px-5" style={{ paddingBottom: "10px" }} >
                  <a rel="noreferrer" target="_blank" href="https://www.facebook.com/infomusiconline" className="btn-floating btn-fb mx-1">
                    <i style={{ color: 'black' }} className="fab fa-facebook-f fa-2x"> </i>
                  </a>
                  <a rel="noreferrer" target="_blank" href="https://twitter.com" className="btn-floating btn-tw mx-1">
                    <i style={{ color: "black" }} className="fab fa-twitter fa-2x"> </i>
                  </a>
                  <a rel="noreferrer" target="_blank" href="https://www.instagram.com/infomusic.online" className="btn-floating btn-li mx-1">
                    <i style={{ color: "black" }} className="fab fa-instagram fa-2x"> </i>
                  </a>
                  <a rel="noreferrer" target="_blank" href="https://www.youtube.com/" className="btn-floating btn-li mx-1">
                    <i style={{ color: "black" }} className="fab fa-youtube fa-2x"> </i>
                  </a>
                </div>
              </Col>
            </Row>

            <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <Col style={{ textAlign: "center" }} >
                <a href="/acerca" style={{ textDecoration: "none", fontFamily: "'Oswald', sans-serif", color: "white" }}>Acerca</a>
              </Col>
              <Col style={{ textAlign: "center" }} >
                <a href="/infomusic" style={{ textDecoration: "none", fontFamily: "'Oswald', sans-serif", color: "white" }}>Que és infoMUSIC?</a>
              </Col>
              <Col style={{ textAlign: "center" }} >
                <a href="/politicaprivacidad" style={{ textDecoration: "none", fontFamily: "'Oswald', sans-serif", color: "white" }}>Política de privacidad</a>
              </Col>
              <Col style={{ textAlign: "center" }} >
                <a href="/contactos" style={{ textDecoration: "none", fontFamily: "'Oswald', sans-serif", color: "white" }}>Contacto</a>
              </Col>
            </Row>
          </>
        )}

      </Container>

      <div className="footer-copyright text-center py-3"
        style={{
          fontWeight: "bold",
          borderTopColor: "black",
          borderTopStyle: "solid",
          borderTopWidth: "1px",
        }}> <br />©2021 infomusiconline:
        <a href="/politicaprivacidad" style={{ textDecoration: "none", fontWeight: "bold", color: "#693B00", }}> Todos los derechos reservados.</a>
      </div>

    </footer>


  );
}

export default Footer;