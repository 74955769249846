import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from '../Firebase';
import firebaseApp from 'firebase/app'
import './profesionalesInfoCSS.css';
import { Button, Carousel } from 'react-bootstrap'
import useWindowDimensions from '../useWindowDimensions.js';

const db = firebaseApp.firestore()

function ProfesionalesInfo() {
  
  const { width } = useWindowDimensions();
  let [profesionales, setProfesionales] = useState([]);
  let [refreshRender, setRefreshRender] = useState([]);

  useEffect(() => {

    //Ler Banco de dados "profesionales" em Realtime Database
    firebase.app.ref('profesionales').on('value', (snapshot) => {

      profesionales = ([])

      snapshot.forEach((childItem) => {
        //Ler Banco de dados "customers" em Cloud Firestore
        db.collection('customers')
          .doc(childItem.key)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(async (snapshot) => {

            refreshRender = ([]) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)

            if (!snapshot.empty) {
              snapshot.forEach(async function (doc) {
                if (doc.data().status === "active" && doc.data().role === "Suscripción Profesionales") {
                  checkSubscription()
                }
              })
            }
            setRefreshRender(refreshRender) //Refresh Render, (ainda estou consfuso sobre isso, não sei explicar)
          }) //Fim Ler Cloud Firestore

        const checkSubscription = () => {
          profesionales.push({
            uid: childItem.key,
            chavepost: childItem.val().chavepost,
            nome: childItem.val().nome,
            imagem: childItem.val().image,
          })
        };

      });
      profesionales.reverse();
      setProfesionales(profesionales);
    });

  }, []); //Fim useEffect


  return (

    <React.Fragment>


      <div className="containerProfesionalesInfo" style={{ minHeight: "630px" }}>

        <div style={{ textAlign: "center", backgroundColor: "#DCBA8C" }}>
          <Button style={{
            backgroundColor: "#DCBA8C", color: "white",
            fontFamily: "'Oswald', sans-serif",
            border: "none",
            pointerEvents: "none",
            cursor: "default",
            fontSize: "30px",
          }}
            variant="primary" >
            infoMUSIC  PROFESIONALES
        </Button>
        </div>
        <br />
        <div style={{ textAlign: "center" }}>
          <p>Encuentra y conecta directamente con el profesional o artísta que necesitas.</p>

          <p>Profesionales de todas las areas relacionadas con la música:
          Artístas, cantantes, instrumentistas, productores, grupos musicales, DJ...</p>

          <p>Toda la información y facilidades para seguir adelante con tu negocio, proyecto o idea.</p>
        </div>

        <br />
        <br />
        <div style={(width >= 1366? {paddingLeft:'400px', paddingRight:'400px'}: null)}>
          <Button href={`/profesionales/cantantes`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Cantantes
        </Button >
          <Button href={`/profesionales/instrumentistas`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Instrumentistas
        </Button>
          <Button href={`/profesionales/grupos musicales`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Grupos Musicale
        </Button>
          <Button href={`/profesionales/orquestas`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Orquestas
        </Button>
          <Button href={`/profesionales/productores`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Productores
        </Button >
          <Button href={`/profesionales/managers`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Managers
        </Button>
          <Button href={`/profesionales/marketing digital`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            Marketing Digital
        </Button>
          <Button href={`/profesionales/dj`} block style={{ backgroundColor: '#DCBA8C', borderColor: "#DCBA8C" }}>
            DJ
        </Button>
        </div>

        <br />

        <div style={{ textAlign: "center" }}>
          <i style={{ color: "#D39B5E" }} className="fas fa-user-tie fa-3x" ></i>
          <br />
          <p>PROFESIONALES</p>
          <br />
        </div >


        <Carousel fade={true} >
          {profesionales.map((post) => {
            return (
              <Carousel.Item interval={1000} >
                <img className={(width > 800 ? 'imagemProfesionalesInfo' : 'imagemProfesionalesInfoMobile')} src={post.imagem} alt="Publication Cover" />
                <Carousel.Caption>
                  <Button href={`/profesionales/publication/${post.chavepost}`} style={{ backgroundColor: '#A36920', borderColor: "#A36920" }}>{post.nome}</Button>
                </Carousel.Caption>
              </Carousel.Item>
            )
          })}
        </Carousel>

      </div>

      {/* icon retorno */}
      <div style={{ textAlign: "center", paddingBottom: "20px", paddingTop: "20px" }}>
        <a href="/profesionales#">
          <i style={{ color: "#693B00" }} className="fas fa-chevron-up fa-3x "></i>
        </a>
      </div>

    </React.Fragment>
  );
}

export default withRouter(ProfesionalesInfo)