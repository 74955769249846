import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../img/infomusicLogo.png'
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import './headerCSS.css';
import firebase from '../Firebase';
import firebaseApp from 'firebase/app';
import 'firebase/auth';
import useWindowDimensions from '../useWindowDimensions.js';
function Header() {

  const { width } = useWindowDimensions();
  let history = useHistory();

  useState({
    nome: localStorage.nome
  });

  async function logout() {
    await firebase.logout()
      .catch((error) => {
        console.log(error);
      });
    localStorage.removeItem("nome");
    history.push("/login")
  }

  return (

    <header style={{ backgroundColor: "#DCBA8C" }} >
      <Container style={{ backgroundColor: "#DCBA8C", paddingTop: "15px" }}>

        {/* 1º linha principal */}
        <Row >

          {/* 1º SubColuna */}
          <Col xs={4} style={{ textAlign: "justify" }} >
            {/* Coluna vazia */}
          </Col>

          {/* 2º SubColuna */}
          <Col xs={4} style={{ textAlign: "center" }} >
            <a href="/">
              <img className={(width >= 1000 ? 'imagemHeader' : 'imagemHeaderMobile')} src={logo} alt="Logo Header" />
            </a>
          </Col>

          {/* 3º SubColuna */}
          <Col xs={4} >
            {(width >= 1366) ? (
              <>
                <Row >

                  <Col style={{ textAlign: "center" }}>
                    {/* <a href="/login" style={{ color: "#e5167a", textDecoration: "none" }}> */}
                      {(!firebase.getCurrent()) ? (
                        <>
                          <a href="/login" style={{ color: "black", textDecoration: "none", }}>
                            <span> Iniciar sesión </span>
                          </a>
                        </>
                      ) : (
                        <>
                          <a href="/dashboard" style={{ color: "red", textDecoration: "none" }} >
                            <span> <i className="fas fa-user-cog" /> Sesión Abierta</span>
                          </a>
                        </>
                      )
                      }
                    {/* </a> */}
                  </Col>
                  <span>|</span>
                  {/* <Col xs={1} style={{ textAlign: "end" }}><span>|</span> </Col> */}

                  <Col style={{ textAlign: "center", paddingRight: '22px' }}>
                    {!firebaseApp.auth().currentUser ? <> <a style={{ color: "black", textDecoration: "none" }} href="/register"> Registrate </a> </> : null}
                    {firebaseApp.auth().currentUser ? <>
                      <a onClick={() => logout()} href="/login" style={{ color: "black", textDecoration: "none", paddingRight: '40px' }} > <i className="fas fa-times" /> Cerrar sesión </a>
                    </> : null}
                  </Col>
                </Row>
                <Row><br /></Row>
                <Row><br /></Row>
                <Row><br /></Row>
                <Row><br /></Row>
                <Row style={{ textAlign: "end", paddingRight: "52px" }}>
                  <Col >
                    <span style={{ fontFamily: "'Oswald', sans-serif" }}>España</span>
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>

        </Row>
        {(width < 1366) ? (<br />) : null}
        {/* 2º linha principal */}
        {(width < 1366) ? (
          <>
            <Row>
              {/* 1º SubColuna */}
              <Col style={{ textAlign: "end", paddingRight: "64px" }}>
                {/* <a href="/login" style={{ color: "#e5167a", textDecoration: "none" }}> */}
                  {(!firebase.getCurrent()) ? (
                    <>
                      <a href="/login" style={{ color: "black", textDecoration: "none" }}>
                        <span> Iniciar sesión </span>
                      </a>
                    </>
                  ) : (
                    <>
                      <a href="/dashboard" style={{ color: "red", textDecoration: "none" }} >
                        <span> <i className="fas fa-user-cog" /> Sesión Abierta</span>
                      </a>
                    </>
                  )
                  }
                {/* </a> */}
              </Col>

            </Row>
          </>
        ) : null}
        {(width < 1366) ? (<br />) : null}
        {/* 3º linha principal */}
        {(width < 1366) ? (
          <>
            <Row>
              {/* 1º SubColuna */}
              <Col style={{ textAlign: "end", paddingRight: "64px" }}>
                {!firebaseApp.auth().currentUser ? <> <a href="/register" style={{ color: "black", textDecoration: "none" }}>Registrate </a> </> : null}
                {firebaseApp.auth().currentUser ? <>
                  <a onClick={() => logout()} href="/login" style={{ color: "black", textDecoration: "none" }}> <i className="fas fa-times" /> Cerrar sesión </a>
                </> : null}
              </Col>



            </Row>
          </>
        ) : null}
        {(width < 1366) ? (<br />) : null}
        {/* 4º linha principal */}
        <Row>
          <Col style={width >= 768 ?
            { textAlign: "right" }
            : { textAlign: "right" }}>
            <div className={(width < 768 ? 'px-5' : 'px-5')} style={{ paddingBottom: "0px" }} >
              <a rel="noreferrer" target="_blank" href="https://www.facebook.com/infomusiconline" className="btn-floating btn-fb mx-1">
                <i style={{ color: 'white', paddingRight: "10px" }} className="fab fa-facebook-f fa-1x"> </i>
              </a>
              <a rel="noreferrer" target="_blank" href="https://twitter.com" className="btn-floating btn-tw mx-1">
                <i style={{ color: "white", paddingRight: "10px" }} className="fab fa-twitter fa-1x"> </i>
              </a>
              <a rel="noreferrer" target="_blank" href="https://www.instagram.com/infomusic.online" className="btn-floating btn-li mx-1">
                <i style={{ color: "white", paddingRight: "10px" }} className="fab fa-instagram fa-1x"> </i>
              </a>
              <a rel="noreferrer" target="_blank" href="https://www.youtube.com/" className="btn-floating btn-li mx-1">
                <i style={{ color: "white" }} className="fab fa-youtube fa-1x"> </i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Navbar expand="xl" className={(width > 1295 ? 'justify-content-center containerHeader' : null)}>

        <div>
          <Navbar.Toggle className="dropdown px-5" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" >
            <Nav >
              <Nav.Item>
                <div className="dropdown px-5 ">
                  <Nav.Link style={{ textTransform: "uppercase" }} className="dropbtn"
                    href={(width >= 1366 ? "/" : null)}>Home</Nav.Link>
                  <div className="dropdown-content">
                    <a href="/infomusic">Qué es infoMUSIC?</a>
                  </div>
                </div>
              </Nav.Item>

              <Nav.Item>
                <div className="dropdown px-5">
                  <Nav.Link style={{ textTransform: "uppercase" }} className="dropbtn"
                    href={(width >= 1366 ? "/profesores" : null)}>Profesores</Nav.Link>
                  <div className="dropdown-content">
                    <a href={`/profesores/canto clasico`}>Canto Clasico</a>
                    <a href={`/profesores/canto moderno`}>Canto Moderno</a>
                    <a href={`/profesores/guitarra`}>Guitarra</a>
                    <a href={`/profesores/guitarra electrica`}>Guitarra Electrica</a>
                    <a href={`/profesores/piano`}>Piano</a>
                    <a href={`/profesores/bateria`}>Bateria</a>
                  </div>
                </div>
              </Nav.Item>

              <Nav.Item>
                <div className="dropdown px-5">
                  <Nav.Link style={{ textTransform: "uppercase" }} className="dropbtn"
                    href={(width >= 1366 ? "/profesionales" : null)}>Profesionales</Nav.Link>
                  <div className="dropdown-content">
                    <a href={`/profesionales/cantantes`}>Cantantes</a>
                    <a href={`/profesionales/instrumentistas`}>Instrumentistas</a>
                    <a href={`/profesionales/grupos musicales`}>Grupos Musicales</a>
                    <a href={`/profesionales/orquestas`}>Orquestas</a>
                    <a href={`/profesionales/productores`}>Productores</a>
                    <a href={`/profesionales/managers`}>Managers</a>
                    <a href={`/profesionales/marketing digital`}>Marketing Digital</a>
                    <a href={`/profesionales/dj`}>DJ</a>
                  </div>
                </div>
              </Nav.Item>

              <Nav.Item>
                <div className="dropdown px-5">
                  <Nav.Link style={{ textTransform: "uppercase" }} className="dropbtn"
                    href={(width >= 1366 ? "/escuelas" : null)}>Escuelas</Nav.Link>

                  <div className="dropdown-content">
                    <a href={`/premium/escuelas de musica`}>Escuelas de Música </a>
                    <a href={`/premium/conservatorio`}>Conservatorio</a>
                    <a href={`/premium/escuela superior de musica`}>Escuela Superior de Música</a>
                    <a href={`/premium/escuelas de musica online`}>Escuelas de Música Online</a>
                    <a href={`/premium/escuelas especiales de musica`}>Escuelas Especiales de Música</a>
                  </div>
                </div>
              </Nav.Item>

              <Nav.Item>
                <div className="dropdown px-5">
                  <Nav.Link style={{ textTransform: "uppercase" }} className="dropbtn"
                    href={(width >= 1366 ? "/tiendas" : null)}>Tiendas</Nav.Link>
                  <div className="dropdown-content">
                    <a href={`/premium/tiendas de musica`}>Tiendas de Mùsica </a>
                    <a href={`/premium/tiendas de musica online`}>Tiendas de Música Online</a>
                    <a href={`/premium/distribuidores de musica`}>Distribuidores de Música</a>
                  </div>
                </div>
              </Nav.Item>

              <Nav.Item>
                <div className="dropdown px-5" >
                  <Nav.Link style={{ textTransform: "uppercase" }} className="dropbtn"
                    href={(width >= 1366 ? "/espacios" : null)}>Espacios</Nav.Link>
                  <div className="dropdown-content">
                    <a href={`/premium/espacios de alquiler`}>Espacios de Alquiler</a>
                    <a href={`/premium/estudios de grabacion`}>Estudios de Grabación</a>
                    <a href={`/premium/coworking`}>Coworking</a>
                  </div>
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>

      </Navbar>
    </header >

  );
}

export default Header;