import React, { useState } from 'react';
import { withRouter} from 'react-router-dom';
import './contatosCSS.css';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap'


function Contatos() {

  const [campos, setCampos] = useState({
    nome: '',
    emailRemetente: '',
    mensagem: '',
  });


  function handleInputChange(event) {
    campos[event.target.name] = event.target.value;
    setCampos(campos);
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    send(campos);
    event.target.reset();
    alert("¡Mensage enviada!");
  }

  function send() {
    axios.post('https://us-central1-infomusic-6e76a.cloudfunctions.net/sendMail', campos)
  }


  return (

    <React.Fragment>


      <div className="containerContatos" >

        <Form onSubmit={handleFormSubmit} id="contatos" >
          <h1 style={{ fontFamily: "'Oswald', sans-serif" }}
            className="register-h1"> Formulario de contacto</h1>
          <Form.Group >
            <Form.Label>Nombre</Form.Label>
            <Form.Control id="nome" name="nome" onChange={handleInputChange} required type="text" placeholder="Enter Nombre" />
          </Form.Group>

          <Form.Group >
            <Form.Label>Email address</Form.Label>
            <Form.Control id="emailRemetente" name="emailRemetente" onChange={handleInputChange} required type="email" placeholder="Enter Email" />
          </Form.Group>

          <Form.Group >
            <Form.Label>Mensaje</Form.Label>
            <Form.Control id="mensagem" name="mensagem" onChange={handleInputChange} required as="textarea" rows={4} placeholder="Escribe algo..." />
          </Form.Group>

          <Button variant="primary" type="submit" value="Enviar">
            Enviar
            </Button>
        </Form>

      </div>

    </React.Fragment >


  );
}

export default withRouter(Contatos)